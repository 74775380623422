import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, PLATFORM_ID, Renderer2, HostListener } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import "flowbite";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "The Kids Capitol";
  public fixed: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private renderer: Renderer2,
    private swUpdate: SwUpdate,
    private router: Router
  ) {
    this.updateBrowser();
  }

  @HostListener("window:scroll", ['$event'])
  onWindowScroll($event: any) {
    let top = window.scrollY;
    if (top >= 100) {
      this.fixed = true;
    } else if (this.fixed && top < 100) {
      this.fixed = false;
    }
  }

  updateBrowser() {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.swUpdate.available.subscribe((event) => {
      this.swUpdate.activateUpdate().then(() => {
        document.location.reload();
      });
    });
  }

  ngAfterViewInit(): void {
    // if (isPlatformBrowser(this.platformId)) {
    //   let loader = this.renderer.selectRootElement("#loader");
    //   if (loader.style.display != "none") loader.style.display = "none"; //hide loader
    //   console.log("Test view init");
    // }
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    });
  }
}
