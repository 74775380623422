import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Observable } from "rxjs";

@Component({
  selector: "app-franchise-page",
  templateUrl: "./franchise-page.component.html",
  styleUrls: ["./franchise-page.component.css"],
})
export class FranchisePageComponent {
  constructor(
    private http: HttpClient,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle("Franchise - The Kids Capitol");
    this.meta.updateTag({
      name: "description",
      content: `Explore lucrative franchise opportunities with The Kids Capitol kids indoor play area. Join our successful network of franchisees and bring the joy of play to children in your area.
        Ready to be your own boss? Discover the rewards of owning a children's entertainment franchise with The Kids Capitol. Learn about our franchise investment opportunities and join our family today.
        Invest in a proven business model with The Kids Capitol indoor playground franchise. Our franchisees enjoy comprehensive support, training, and a rewarding opportunity to make a difference in children's lives.
        Join our team and become a part of our successful business model. Contact us today!`,
    });
    this.meta.updateTag({
      name: "keywords",
      content:
        "Franchise opportunities, Kids indoor play area franchise, Children's entertainment franchise, Indoor playground franchise, Family fun center franchise, Franchise investment, Business opportunity for kids play area, Kids activity center franchise, Children's franchise business, Franchise for indoor play park",
    });

    this.meta.updateTag({
      property: "og:title",
      content: "Franchise - The Kids Capitol",
    });
    this.meta.updateTag({
      property: "og:description",
      content: `Explore lucrative franchise opportunities with The Kids Capitol kids indoor play area. Join our successful network of franchisees and bring the joy of play to children in your area.
        Ready to be your own boss? Discover the rewards of owning a children's entertainment franchise with The Kids Capitol. Learn about our franchise investment opportunities and join our family today.
        Invest in a proven business model with The Kids Capitol indoor playground franchise. Our franchisees enjoy comprehensive support, training, and a rewarding opportunity to make a difference in children's lives.
        Join our team and become a part of our successful business model. Contact us today!`,
    });

    this.meta.updateTag({
      property: "twitter:title",
      content: "Franchise - The Kids Capitol",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content: `Explore lucrative franchise opportunities with The Kids Capitol kids indoor play area. Join our successful network of franchisees and bring the joy of play to children in your area.
        Ready to be your own boss? Discover the rewards of owning a children's entertainment franchise with The Kids Capitol. Learn about our franchise investment opportunities and join our family today.
        Invest in a proven business model with The Kids Capitol indoor playground franchise. Our franchisees enjoy comprehensive support, training, and a rewarding opportunity to make a difference in children's lives.
        Join our team and become a part of our successful business model. Contact us today!`,
    });
  }

  downloadBrochure() {
    const fileUrl = "/assets/The-Kids-Capitol-Franchise.pdf";
    this.downloadFile(fileUrl).subscribe((blob) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "The-Kids-Capitol-Franchise.pdf";
      link.click();
    });
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: "blob" });
  }
}
