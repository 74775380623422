<div
  class="sticky top-0 z-30 text-center text-white"
  [ngClass]="{ 'bg-color-purple': !comingSoon }">
  <nav class="md:header-nav" [ngClass]="{ 'bg-color-purple': !comingSoon }">
    <div
      class="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4 md:p-5 md:pt-7">
      <a href="#" class="">
        <img
          src="../../../assets/images/logo.png"
          class="rotating absolute top-4 z-30 mr-3 h-14 md:h-32"
          alt="The Kids Capitol Logo" />
      </a>
      <button
        data-collapse-toggle="navbar-default"
        type="button"
        class="mb-1 ml-3 inline-flex items-center rounded-lg p-2 text-sm text-color-orange focus:outline-none focus:ring-2 focus:ring-color-orange md:hidden"
        aria-controls="navbar-default"
        aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg
          class="h-6 w-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clip-rule="evenodd"></path>
        </svg>
      </button>
      <div
        *ngIf="!comingSoon"
        class="relative z-30 hidden w-full bg-color-purple md:static md:block md:w-auto"
        id="navbar-default">
        <ul
          class="absolute -top-3.5 z-50 mt-4 flex w-full flex-col rounded-lg bg-color-purple p-4 font-medium md:static md:mt-0 md:flex-row md:space-x-8 md:p-0">
          <li>
            <a
              href="#home"
              class="block rounded py-2 pl-3 pr-4 font-lucky uppercase text-color-blue md:bg-transparent md:p-0 md:hover:scale-150"
              aria-current="page">
              Home
            </a>
          </li>

          <li>
            <a
              href="#about-us"
              class="block rounded py-2 pl-3 pr-4 font-lucky uppercase text-color-green md:bg-transparent md:p-0 md:hover:scale-150">
              About Us
            </a>
          </li>
          <li>
            <a
              href="#explore"
              class="block rounded py-2 pl-3 pr-4 font-lucky uppercase text-color-orange md:bg-transparent md:p-0 md:hover:scale-150">
              Explore
            </a>
          </li>
          <li *ngIf="showGallery">
            <a
              href="#gallery"
              class="block rounded py-2 pl-3 pr-4 font-lucky uppercase text-color-pink md:bg-transparent md:p-0 md:hover:scale-150">
              Gallery
            </a>
          </li>
          <li>
            <a
              href="#contact"
              class="block rounded py-2 pl-3 pr-4 font-lucky uppercase text-color-yellow md:bg-transparent md:p-0 md:hover:scale-150">
              Contact
            </a>
          </li>
          <li class="flex justify-center gap-2">
            <a
              class="hover:scale-150"
              href="//www.instagram.com/thekidscapitol"
              target="_blank"
              title="Visit our Instagram account">
              <img
                height="25"
                width="25"
                src="../../../assets/images/instagram.png"
                alt="Instagram logo" />
            </a>

            <a
              class="hover:scale-150"
              href="//www.facebook.com/thekidscapitol"
              target="_blank"
              title="Visit our Facebook account">
              <img
                height="25"
                width="25"
                src="../../../assets/images/facebook.png"
                alt="Facebook logo" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>

<section
  *ngIf="!comingSoon"
  id="home"
  class="flex justify-start bg-amber-400 px-5 md:h-auto md:flex-row md:justify-between">
  <div
    class="mx-auto w-3/4 max-w-screen-xl flex-col justify-center rounded-lg px-4 text-center lg:py-10">
    <div class="relative mb-5 flex justify-center">
      <!-- Clouds start -->
      <div class="relative">
        <div class="absolute left-24 top-3 scale-75 md:scale-100">
          <div class="sky">
            <div class="cloud"></div>
          </div>
        </div>
        <div class="absolute left-4 top-14 scale-50 md:left-8">
          <div class="sky">
            <div class="cloud"></div>
          </div>
        </div>
        <div class="absolute left-36 top-12 scale-50 md:left-48 md:top-8">
          <div class="sky">
            <div class="cloud"></div>
          </div>
        </div>
      </div>
      <!-- Clouds end -->

      <img
        class="w-100 h-40"
        src="../../../assets/images/play-zone-header.png"
        alt="Indoor Play Zone" />
    </div>

    <h1
      class="mb-4 pb-3 text-center font-bubblegum text-3xl font-bold leading-none tracking-tight text-color-purple md:text-5xl">
      Kerala's Largest Gadget Free Indoor Kids Play Zone
    </h1>

    <p
      class="mb-8 font-bubblegum text-lg font-normal text-color-purple sm:px-16 lg:px-48 lg:text-xl">
      Welcoming your little wonders aged 0 to 14 years to come, immerse in hours
      of play, unleash their imagination at Kerala's First, Largest and Ultimate
      Gadget-free Wonderland
      <br />
      <b>- The Kids Capitol</b>
      .
    </p>
  </div>
  <img
    class="hidden md:block md:h-96"
    src="../../../assets/images/fox-anime.png"
    alt="Fox welcoming" />
  <app-background-animation></app-background-animation>
</section>

<!-- About section -->
<div class="relative">
  <img
    src="../../../assets/images/about-us-div-wave.svg"
    alt="Divider"
    class="absolute bottom-1 top-0 w-full" />
</div>
<section
  *ngIf="!comingSoon"
  id="about-us"
  class="flex flex-col gap-y-2 bg-purple-900 p-5 py-10 md:h-auto">
  <div
    class="section-heading flex flex-col items-center justify-between gap-y-2">
    <img src="../../../assets/images/heading-2.png" alt="Explore Heading" />

    <h2 class="font-lucky text-4xl uppercase">
      <span class="pr-1 text-color-blue">About &nbsp;</span>
      <span class="pr-1 text-color-orange">us</span>
    </h2>
    <img class="w-28" src="../../../assets/images/divider.png" alt="Divider" />
  </div>
  <div class="flex justify-start md:flex-row md:justify-start">
    <div class="relative">
      <img
        class="float-animation absolute hidden md:block md:h-96"
        src="../../../assets/images/welcoming-star.png"
        alt="Welcoming star" />
      <img
        class="hidden md:block md:h-96"
        src="../../../assets/images/play-balls.png"
        alt="Play balls" />
    </div>

    <div
      class="text-md container mx-auto flex max-w-screen-lg flex-col gap-y-10 rounded p-4 pb-10 font-proza text-color-orange md:text-xl">
      <p>
        Imagine a play space where your kids are not amazed by the screens and
        digital illusions, but stay amused for hours with all sweat and play &
        fun and frolic! The Kids Capitol is that space for your wonderful kids
        to come unplug, imagine, play and have unlimited fun! With lots of
        innovative, thoughtful and physically fun filled activities, let your
        little ones explore the world of play and learning together here.
      </p>

      <p>
        Add spark to their birthdays and beat their hunger pangs with
        yummilicious food with us at The Kids Capitol.
      </p>

      <p>
        This wonderful space is inspired by a mother herself, so you can be
        assured of the child safety and covid safety norms that will be followed
        to keep your little ones safe.
      </p>
    </div>
  </div>
</section>

<!-- Explore section -->
<div class="relative">
  <img
    src="../../../assets/images/explore-div.svg"
    alt="Divider"
    class="absolute bottom-1 top-0 w-full" />
</div>
<section
  *ngIf="!comingSoon"
  id="explore"
  class="flex flex-col gap-y-2 bg-rose-500 p-5 md:h-auto">
  <div
    class="section-heading flex flex-col items-center justify-between gap-y-2">
    <img
      class="balloons-animation w-100 mb-2 h-32"
      src="../../../assets/images/heading-children.png"
      alt="Explore Joy Of Playing" />
    <h2 class="mt-3 text-center font-lucky text-3xl uppercase">
      <span class="pr-1 text-color-blue">Explore &nbsp;</span>
      <span class="pr-1 text-color-orange">joy &nbsp;</span>
      <span class="pr-1 text-color-green">of &nbsp;</span>
      <span class="text-color-purple">playing</span>
    </h2>
    <img class="w-28" src="../../../assets/images/divider.png" alt="Divider" />
  </div>
  <div class="flex justify-start md:flex-row md:justify-start">
    <div class="container mx-auto rounded p-4 text-color-orange">
      <div class="flex flex-wrap justify-center gap-3">
        <div
          class="max-w-xs rounded-xl bg-white shadow-2xl"
          *ngFor="let service of services; let i = index">
          <div class="h-60 w-full rounded-t-xl bg-gray-200">
            <img
              class="h-60 w-full rounded-t-xl"
              [src]="service.imageSrc"
              alt="" />
          </div>
          <div class="p-5 font-proza text-gray-700">
            <h5 class="mb-2 text-2xl font-bold tracking-tight">
              {{ service.name }}
            </h5>
            <p
              class="mb-3 text-sm font-normal"
              [innerHTML]="service.description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Gallery section -->
<section
  *ngIf="!comingSoon && showGallery"
  id="gallery"
  class="multi-color-blue relative z-10">
  <div class="absolute right-0 top-0">
    <img
      class="hidden md:block"
      style="height: 170px"
      src="../../../assets/images/clouds.png"
      alt="clouds" />
    <img
      class="absolute bottom-10 left-28 hidden md:block"
      src="../../../assets/images/sun.png"
      alt="sun" />
  </div>
  <img
    class="absolute right-36 hidden h-12 md:block"
    src="../../../assets/images/bird.gif"
    alt="bird" />
  <div class="flex-col items-center justify-between px-5 pb-10 md:h-auto">
    <div
      class="section-heading relative flex flex-col items-center justify-between gap-y-2">
      <img
        src="../../../assets/images/gallery-heading.png"
        alt="Gallery Heading" />
      <h2 class="font-lucky text-3xl uppercase">
        <span class="text-color-pink">Gallery</span>
      </h2>
      <img
        class="w-28"
        src="../../../assets/images/divider.png"
        alt="Divider" />
    </div>
    <div class="container mx-auto md:w-3/4">
      <div class="flex flex-wrap items-center justify-center py-4 md:py-8">
        <button
          type="button"
          class="mb-3 mr-3 rounded-full border border-blue-600 bg-blue-600 px-5 py-2.5 text-center font-lucky text-base font-medium text-white hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">
          All
        </button>
        <button
          type="button"
          class="font-mediu text-whit mb-3 mr-3 rounded-full border bg-color-orange px-5 py-2.5 text-center font-lucky text-base text-white focus:outline-none focus:ring-4 focus:ring-gray-300">
          Events
        </button>
      </div>
      <div class="grid grid-cols-2 gap-4 md:grid-cols-3">
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image.jpg"
            alt="" />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg"
            alt="" />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-2.jpg"
            alt="" />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-3.jpg"
            alt="" />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-4.jpg"
            alt="" />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-5.jpg"
            alt="" />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-6.jpg"
            alt="" />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-7.jpg"
            alt="" />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-8.jpg"
            alt="" />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-9.jpg"
            alt="" />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-10.jpg"
            alt="" />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-11.jpg"
            alt="" />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Contact Us section -->
<div class="relative">
  <img
    src="../../../assets/images/contact-us-div-pattern.svg"
    alt="Divider"
    class="absolute bottom-1 top-0 w-full" />
</div>
<section
  *ngIf="!comingSoon"
  id="contact"
  class="flex flex-col gap-y-2 bg-amber-500 md:h-auto">
  <div
    class="section-heading flex flex-col items-center justify-between gap-y-2">
    <h2 class="font-lucky text-4xl uppercase">
      <span class="pr-1 text-color-purple">Contact &nbsp;</span>
      <span class="pr-1 text-color-pink">us</span>
    </h2>
    <img class="w-28" src="../../../assets/images/divider.png" alt="Divider" />
  </div>
  <div
    class="container mx-auto mt-10 flex max-w-screen-md flex-col justify-between gap-20 rounded p-4 font-proza text-xl md:flex-row">
    <a
      href="mailto:thekidscapitol@gmail.com"
      class="border-gray-20 multi-color-yellow relative h-44 w-full rounded-lg p-6 shadow-2xl md:w-4/5 md:hover:scale-150">
      <img
        class="absolute left-1/2 h-20 -translate-x-1/2 transform"
        style="bottom: 150px"
        src="../../../assets/images/email.png"
        alt="Email" />
      <h5
        class="mb-2 mt-4 text-2xl font-semibold tracking-tight text-color-purple">
        Send us a Message
      </h5>
      <b class="inline-flex items-center text-blue-600 hover:underline">
        thekidscapitol@gmail.com
      </b>
    </a>

    <a
      href="tel:+917736572227"
      class="border-gray-20 multi-color-purple relative h-44 w-full rounded-lg p-6 text-color-yellow shadow-2xl md:w-4/5 md:hover:scale-150">
      <img
        class="absolute left-1/2 h-20 -translate-x-1/2 transform"
        style="bottom: 150px"
        src="../../../assets/images/telephone.png"
        alt="Call us" />
      <h5 class="mb-2 mt-4 text-2xl font-semibold tracking-tight">Call us</h5>
      <b class="inline-flex items-center font-extrabold hover:underline">
        +91 7736572227
      </b>
    </a>
  </div>

  <div
    class="multi-color-purple mx-auto mb-10 max-w-screen-md rounded-lg border px-4 py-8 font-proza shadow-2xl md:w-1/2 lg:py-16">
    <div class="flex items-center justify-center gap-2">
      <img
        class="inline"
        src="../../../assets/images/heart.png"
        alt="heart"
        height="auto"
        width="auto" />
      <h1 class="text-center font-lucky text-yellow-400">Reach out to us</h1>
    </div>

    <form
      #customerForm="ngForm"
      (ngSubmit)="onSubmit($event, customerForm)"
      class="space-y-8 p-5 text-white md:p-10">
      <div>
        <label for="name" class="mb-2 block text-sm font-medium text-white">
          Your Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          class="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm"
          placeholder="Full Name"
          [(ngModel)]="customer.name"
          #name="ngModel"
          required />
        <div
          class="text-orange-400"
          *ngIf="
            (name.touched || customerForm.submitted) && name.errors?.required
          ">
          Name is required
        </div>
      </div>
      <div>
        <label for="email" class="mb-2 block text-sm font-medium text-white">
          Your Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          class="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm"
          placeholder="name@email.com"
          [(ngModel)]="customer.email"
          #email="ngModel"
          email
          required />
        <div
          class="text-orange-400"
          *ngIf="
            (email.touched || customerForm.submitted) && email.errors?.required
          ">
          Email is required
        </div>
        <div
          class="text-orange-400"
          *ngIf="
            (email.touched || customerForm.submitted) &&
            !email.valid &&
            !email.errors?.required
          ">
          Email is invalid
        </div>
      </div>
      <div>
        <label for="phone" class="mb-2 block text-sm font-medium text-white">
          Your Phone Number
        </label>
        <input
          type="tel"
          id="phone"
          name="phone"
          #phone="ngModel"
          class="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm"
          placeholder="+91 9478389211"
          [(ngModel)]="customer.phone"
          required />
        <div
          class="text-orange-400"
          *ngIf="
            (phone.touched || customerForm.submitted) && phone.errors?.required
          ">
          Phone number is required
        </div>
      </div>
      <div class="sm:col-span-2">
        <label for="message" class="mb-2 block text-sm font-medium text-white">
          Your Message
        </label>
        <textarea
          id="message"
          rows="6"
          name="message"
          #message="ngModel"
          class="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm"
          placeholder="Send us a message.."
          [(ngModel)]="customer.message"
          required></textarea>
        <div
          class="text-orange-400"
          *ngIf="
            (message.touched || customerForm.submitted) &&
            message.errors?.required
          ">
          Message is required
        </div>
      </div>
      <button
        type="submit"
        class="rounded-lg bg-color-green p-2 shadow-2xl hover:bg-green-800"
        [disabled]="!customerForm.form.valid">
        Send message
      </button>
    </form>
  </div>

  <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15718.891156886713!2d76.3546621!3d9.9570065!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b08731b8bf63fe1%3A0xead9027a8542e977!2sThe%20Kids%20Capitol!5e0!3m2!1sen!2sin!4v1690546850860!5m2!1sen!2sin"
    class="relative h-96 w-full"
    allowfullscreen=""
    loading="lazy"></iframe>
</section>

<!-- Footer section -->
<footer
  *ngIf="!comingSoon"
  id="footer"
  class="w-full bg-color-purple p-5 md:h-auto">
  <div
    class="mx-auto my-10 flex w-full items-center justify-between font-lucky md:w-1/2">
    <h1 class="text-2xl text-color-blue md:text-5xl">Unplug</h1>
    <span class="block h-4 w-4 rounded-full bg-color-green"></span>
    <h1 class="text-2xl text-color-orange md:text-5xl">Imagine</h1>
    <span class="block h-4 w-4 rounded-full bg-color-green"></span>
    <h1 class="text-2xl text-color-pink md:text-5xl">Play</h1>
  </div>
  <hr />
  <p class="pt-4 text-center text-sm text-gray-500">
    Copyright © 2023 | The Kids Capitol | All Rights Reserved
  </p>
</footer>

<!-- Modal -->
<div
  id="modal"
  #modal
  tabindex="-1"
  aria-hidden="true"
  class="fixed left-0 right-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0">
  <div class="relative max-h-full w-full max-w-2xl">
    <!-- Modal content -->
    <div class="relative rounded-lg bg-white shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div class="flex items-start justify-between rounded-t border-b p-4">
        <h3>{{ headerMessage }}</h3>
        <button
          (click)="toggleModal()"
          type="button"
          class="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white">
          <svg
            class="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>
      <!-- Modal body -->
      <div class="space-y-6 p-6" [innerHTML]="message"></div>
    </div>
  </div>
</div>
<div class="fixed bottom-4 right-4">
  <a
    href="https://wa.me/7736572227?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20The%20Kids%20Capitol"
    target="_blank"
    title="Chat with us">
    <img
      class="h-10 w-10 md:h-12 md:w-12"
      src="../../../assets/images/whatsapp.png"
      alt="Whatsapp logo" />
  </a>
</div>
