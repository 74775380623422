import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HomeComponent } from "./home/home.component";
import { BackgroundAnimationComponent } from "./background-animation/background-animation.component";
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from "@angular/router";
import { PlayAreaActivitiesComponent } from './play-area-activities/play-area-activities.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
@NgModule({
  declarations: [HomeComponent, BackgroundAnimationComponent, HeaderComponent, FooterComponent, PlayAreaActivitiesComponent, SvgIconComponent, GetInTouchComponent],
  imports: [CommonModule, FormsModule, RouterModule],
  exports: [HomeComponent, BackgroundAnimationComponent, HeaderComponent, FooterComponent, PlayAreaActivitiesComponent, SvgIconComponent, GetInTouchComponent],
})
export class SharedModule { }
