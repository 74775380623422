import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { SharedService } from "src/app/services/shared.service";
import { WebSocketService } from "src/app/services/web-socket.service";

@Component({
  selector: "app-food-capitol-cafe-page",
  templateUrl: "./food-capitol-cafe-page.component.html",
  styleUrls: ["./food-capitol-cafe-page.component.css"],
})
export class FoodCapitolCafePageComponent {
  foodMenuOne: any = [];
  foodMenuTwo: any = [];
  beveragesOne: any = [];
  beveragesTwo: any = [];
  cafeGalleryImages: any = [];

  constructor(
    private webSocketService: WebSocketService,
    private sharedService: SharedService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.getCafeData();
    this.titleService.setTitle("The Kids Capitol Cafe");
    this.meta.updateTag({
      name: "description",
      content:
        "Cafe page of The Kids Capitol is Kerala's largest gadget free indoor kids play zone.",
    });
    this.meta.updateTag({
      name: "keywords",
      content: `cafe, kids menu, children's food, beverages, juices, snacks, healthy options, kid-friendly cuisine, food for children, family-friendly cafe, refreshments, play area cafe, indoor play zone cafe, nutritious snacks, drinks for kids, fun dining, Kerala, children's activities`,
    });
  }

  getCafeData() {
    let url = "../../../assets/json/cafe-menu.json";
    this.webSocketService.getObjects(url).subscribe(
      (data) => {
        data.map((val: any) => {
          if (val.foodMenu) {
            this.foodMenuOne = val.foodMenu.slice(0, 5);
            this.foodMenuTwo = val.foodMenu.slice(5, val.foodMenu.length);
          }

          if (val.beverages) {
            this.beveragesOne = val.beverages.slice(0, 5);
            this.beveragesTwo = val.beverages.slice(5, val.beverages.length);
          }

          if (val.gallery) {
            this.cafeGalleryImages = val.gallery;
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  redirectToTestimonials() {
    this.sharedService.redirectToPage("/testimonials");
  }
}
