<div class="relative">
  <img
    src="../../../assets/images/contact-us-div-pattern.svg"
    alt="Divider"
    class="absolute bottom-1 top-0 w-full" />
</div>
<section class="bg-contact-bg bg-cover bg-center bg-no-repeat">
  <div class="left-0 top-0 w-full rotate-180 overflow-hidden leading-none">
    <!-- <svg
      class="relative block w-full lg:h-32"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 120"
      preserveAspectRatio="none">
      <path
        d="M985.66 92.83C906.67 72 823.78 31 743.84 14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84 11.73-114 31.07-172 41.86A600.2 600.2 0 0 1 0 27.35V120h1200V95.8c-67.81 23.12-144.29 15.51-214.34-2.97"
        class="shape-fill" />
    </svg> -->
  </div>
  <div class="h-full w-full">
    <div class="mt-16 px-7 lg:mt-32 lg:px-10">
      <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
        <div class="mb-6 flex flex-col lg:mb-0">
          <div class="mb-5 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:mb-0">
            <div class="mr-4 flex lg:justify-center">
              <img
                routerLink="/home"
                class="animate-float h-40 cursor-pointer"
                src="../../../assets/images/logo.png"
                alt="Logo" />
            </div>
            <div
              class="animation-delay-300 animation-fill-mode-forwards animate-reveal text-white opacity-0 sm:pl-6">
              <h5 class="mb-4 font-semibold">IMPORTANT LINKS</h5>
              <ul class="list-none font-semibold text-white">
                <li
                  class="mb-1 transform transition-all duration-500 ease-in-out hover:text-gray-300">
                  <a class="cursor-pointer" href="/home">Home</a>
                </li>
                <li
                  class="mb-1 transform transition-all duration-500 ease-in-out hover:text-gray-300">
                  <a class="cursor-pointer" href="/about">About Us</a>
                </li>
                <li
                  class="mb-1 transform transition-all duration-500 ease-in-out hover:text-gray-300">
                  <a class="cursor-pointer" href="/franchise">
                    Start Your Own Play Area
                  </a>
                </li>
                <li
                  class="transform transition-all duration-500 ease-in-out hover:text-gray-300">
                  <a class="cursor-pointer" href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-3 lg:pl-20">
            <div class="mb-6 grid grid-cols-2 gap-2">
              <div class="flex flex-nowrap content-center">
                <a
                  class="mr-3 transform transition-all duration-500 ease-in-out hover:translate-y-[-4px]"
                  href="//www.instagram.com/thekidscapitol"
                  target="_blank"
                  title="Visit our Instagram account">
                  <img
                    height="40"
                    width="40"
                    src="../../../assets/images/instagram.png"
                    alt="Instagram logo" />
                </a>

                <a
                  class="mr-3 transform transition-all duration-500 ease-in-out hover:translate-y-[-4px]"
                  href="//www.facebook.com/thekidscapitol"
                  target="_blank"
                  title="Visit our Facebook account">
                  <img
                    height="40"
                    width="40"
                    src="../../../assets/images/facebook.png"
                    alt="Facebook logo" />
                </a>
                <a
                  class="transform transition-all duration-500 ease-in-out hover:translate-y-[-4px]"
                  href="//www.youtube.com/@ThekidsCapitol"
                  target="_blank"
                  title="Visit our Youtube account">
                  <img
                    height="40"
                    width="40"
                    src="../../../assets/images/YouTube.png"
                    alt="Youtube logo" />
                </a>
              </div>
            </div>
            <!-- <div class="mt-3">
              <p class="text-white">
                *Please note that our weekend opening hours can be affected by
                scheduled Private Events.
              </p>
            </div> -->
          </div>
        </div>
        <div class="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <div
            class="animation-delay-600 animation-fill-mode-forwards mb-4 animate-reveal text-white opacity-0 sm:mb-0">
            <h5 class="mb-4 font-semibold">OPENING HOURS</h5>
            <p class="mb-2">Mon - Thu: 1:00 pm - 9:00 pm</p>
            <p class="mb-2">Fri - Sun: 11:00 am - 10:00 pm</p>
            <p class="mb-2">Public Holidays: 11:00 am - 10:00 pm</p>
          </div>
          <div
            class="animation-delay-900 animation-fill-mode-forwards animate-reveal pb-8 text-white opacity-0">
            <h5 class="mb-4 font-semibold">CONTACT DETAILS</h5>
            <p class="mb-3 flex flex-nowrap">
              <span class="mr-1 mt-1 pr-1">
                <svg
                  class="h-[16px] w-[16px] text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20">
                  <path
                    d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205q.31-.333.578-.7l.127-.155a1 1 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0m0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                </svg>
              </span>
              <span>
                1st floor, Carnival High Street, National Highway 49, Irumpanam,
                Thrippunithura, Kochi, Ernakulam, Kerala 682301
              </span>
            </p>
            <p class="mb-3 flex flex-nowrap">
              <span class="mr-1 mt-1 pr-1">
                <svg
                  class="h-[16px] w-[16px] text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18">
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.98 1.98 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22s7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804" />
                </svg>
              </span>
              <span>+91 7736572227</span>
            </p>
            <p class="mb-3 flex flex-nowrap">
              <span class="mr-1 mt-1 pr-1">
                <svg
                  class="h-[16px] w-[16px] text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16">
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2" />
                </svg>
              </span>
              <span>thekidscapitol@gmail.com</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<footer id="footer" class="w-full bg-color-purple p-5 md:h-auto">
  <div
    class="mx-auto my-10 flex w-full items-center justify-between font-lucky md:w-1/2">
    <h1 class="text-2xl text-color-blue md:text-3xl lg:text-5xl">Unplug</h1>
    <span class="block h-4 w-4 rounded-full bg-color-green"></span>
    <h1 class="text-2xl text-color-orange md:text-3xl lg:text-5xl">Imagine</h1>
    <span class="block h-4 w-4 rounded-full bg-color-green"></span>
    <h1 class="text-2xl text-color-pink md:text-3xl lg:text-5xl">Play</h1>
  </div>
  <hr />
  <p class="pt-4 text-center text-sm text-gray-500">
    Copyright © 2024 | The Kids Capitol | All Rights Reserved
  </p>
</footer>
