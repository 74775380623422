<div class="font-poppins">
  <nav class="start-0 top-0 z-20 w-full bg-color-purple">
    <div
      class="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between px-6 py-6 lg:py-0 xl:px-0">
      <a routerLink="/home" class="items-center space-x-3 rtl:space-x-reverse">
        <img
          src="../../../assets/images/logo.png"
          class="absolute top-4 z-50 mr-3 h-16 sm:h-28 lg:h-32"
          alt="The Kids Capitol Logo" />
      </a>
      <div class="flex space-x-3 rtl:space-x-reverse lg:order-2 lg:space-x-0">
        <div class="relative right-6 hidden flex-wrap content-center sm:flex">
          <div class="flex flex-nowrap space-x-4">
            <a
              class="hover:scale-150"
              href="//www.instagram.com/thekidscapitol"
              target="_blank"
              title="Visit our Instagram account">
              <img
                height="25"
                width="25"
                src="../../../assets/images/instagram.png"
                alt="Instagram logo" />
            </a>
            <a
              class="hover:scale-150"
              href="//www.facebook.com/thekidscapitol"
              target="_blank"
              title="Visit our Facebook account">
              <img
                height="25"
                width="25"
                src="../../../assets/images/facebook.png"
                alt="Facebook logo" />
            </a>
            <a
              class="hover:scale-150"
              href="//www.youtube.com/@ThekidsCapitol"
              target="_blank"
              title="Visit our Youtube account">
              <img
                height="26"
                width="26"
                src="../../../assets/images/YouTube.png"
                alt="Youtube logo" />
            </a>
          </div>
        </div>
        <button
          (click)="openMenu()"
          type="button"
          class="inline-flex h-10 w-10 items-center justify-center rounded-lg p-2 text-sm text-color-orange lg:hidden">
          <span class="sr-only">Open main menu</span>
          <svg
            class="h-5 w-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14">
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
      </div>
      <div
        class="absolute top-20 z-40 w-86 items-center justify-between pl-0 sm:w-11/12 lg:static lg:z-1 lg:order-1 lg:flex lg:w-auto lg:pl-28"
        [ngClass]="{ 'hidden lg:block': !isMenuOpen }">
        <ul
          class="mt-0 flex h-full flex-col space-x-4 rounded-lg bg-color-purple text-base font-medium rtl:space-x-reverse lg:mt-0 lg:flex-row xl:space-x-8">
          <!-- <li class="block cursor-pointer lg:hidden">
            <a
              (click)="openMenu()"
              class="relative -left-6 top-2 text-2xl font-light">
              x
            </a>
          </li> -->
          <!-- <li class="sm:hidden">
            <a
              routerLink="/home"
              class="flex justify-center space-x-3 rtl:space-x-reverse">
              <img
                src="../../../assets/images/logo.png"
                class="rotating z-30 mr-3 h-28"
                alt="The Kids Capitol Logo" />
            </a>
          </li> -->
          <!-- <li class="group static py-0 lg:relative lg:py-7">
            <button
              (click)="openSubMenu()"
              routerLink="/about"
              class="flex w-full items-center justify-center rounded px-3 py-2 font-lucky uppercase text-color-green hover:scale-150 lg:justify-between lg:p-0">
              About
              <span class="relative left-2">
                <svg
                  [ngClass]="{
                    'hidden lg:hidden': isSubMenuOpen,
                    'block lg:hidden': !isSubMenuOpen
                  }"
                  class="h-3 w-3 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 8">
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
                </svg>
                <svg
                  [ngClass]="{
                    'hidden lg:hidden': !isSubMenuOpen,
                    'block lg:hidden': isSubMenuOpen
                  }"
                  class="h-3 w-3 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 8">
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7" />
                </svg>
              </span>
            </button>
            
            <div
              [ngClass]="{ 'hidden lg:block': !isSubMenuOpen }"
              class="visible static -left-10 top-full z-30 block w-full divide-y divide-gray-100 rounded-lg bg-color-purple font-normal opacity-0 transition-all duration-500 group-hover:opacity-100 lg:invisible lg:absolute lg:mt-1 lg:w-44 lg:shadow lg:group-hover:visible">
              <ul
                class="py-2 font-lucky text-sm uppercase"
                aria-labelledby="dropdownLargeButton">
                <li class="cursor-pointer">
                  <a
                    routerLink="/gallery"
                    class="block px-4 py-3 text-center text-color-yellow hover:scale-125">
                    Gallery
                  </a>
                </li>
                <li class="cursor-pointer">
                  <a
                    routerLink="/news-room"
                    class="block px-4 py-3 text-center text-color-pink hover:scale-125">
                    News Room
                  </a>
                </li>
                <li class="cursor-pointer">
                  <a
                    routerLink="/events-workshops"
                    class="block px-4 py-3 text-center text-color-blue hover:scale-125">
                    Events & Workshops
                  </a>
                </li>
                <li class="cursor-pointer">
                  <a
                    routerLink="/testimonials"
                    class="block px-4 py-3 text-center text-color-orange hover:scale-125">
                    Testimonials
                  </a>
                </li>
              </ul>
            </div>
          </li> -->
          <!-- <li
            class="transfor py-0 transition-all duration-500 ease-in-out lg:py-7">
            <a
              routerLink="/attractions"
              class="block rounded px-3 py-2 text-center font-lucky uppercase text-color-orange hover:scale-150 lg:p-0 lg:text-start">
              Attractions
            </a>
          </li>
          <li
            class="transform py-0 transition-all duration-500 ease-in-out lg:py-7">
            <a
              routerLink="/pricing"
              class="block rounded px-3 py-2 text-center font-lucky uppercase text-color-blue hover:scale-150 lg:p-0 lg:text-start">
              Pricing
            </a>
          </li>
          <li
            class="transform py-0 transition-all duration-500 ease-in-out lg:py-7">
            <a
              routerLink="/the-food-capitol-cafe"
              class="block rounded px-3 py-2 text-center font-lucky uppercase text-purple-700 hover:scale-150 lg:p-0 lg:text-start">
              The Food Capitol
            </a>
          </li> -->
          <li
            class="transform py-0 transition-all duration-500 ease-in-out lg:py-7"
            (click)="closeMenu()">
            <a
              routerLink="/home"
              class="block rounded px-3 py-2 text-center font-lucky uppercase text-color-blue hover:scale-150 lg:text-start">
              Home
            </a>
          </li>
          <li
            (click)="closeMenu()"
            class="transform py-0 transition-all duration-500 ease-in-out lg:py-7">
            <a
              routerLink="/about"
              class="block rounded px-3 py-2 text-center font-lucky uppercase text-color-green hover:scale-150 lg:text-start">
              About Us
            </a>
          </li>
          <li
            (click)="closeMenu()"
            class="transform py-0 transition-all duration-500 ease-in-out lg:py-7">
            <a
              routerLink="/franchise"
              class="xl:hover::px-7 block rounded px-3 py-2 text-center font-lucky uppercase text-color-pink hover:scale-150 lg:text-start lg:hover:px-8">
              Start Your Own Play Area
            </a>
          </li>
          <li
            class="transform cursor-pointer py-0 pb-3 transition-all duration-500 ease-in-out lg:py-7 lg:pb-3">
            <a
              (click)="closeMenu()"
              routerLink="/contact"
              class="block rounded px-3 py-2 text-center font-lucky uppercase text-color-yellow hover:scale-150 lg:text-start">
              Contact
            </a>
          </li>
          <li class="sm:hidden">
            <div
              class="flex flex-col px-6 pb-6 rtl:space-x-reverse lg:order-2 lg:space-x-0">
              <div class="flex flex-wrap content-center justify-center">
                <div class="flex flex-nowrap space-x-4">
                  <a
                    (click)="closeMenu()"
                    class="hover:scale-150"
                    href="//www.instagram.com/thekidscapitol"
                    target="_blank"
                    title="Visit our Instagram account">
                    <img
                      height="25"
                      width="25"
                      src="../../../assets/images/instagram.png"
                      alt="Instagram logo" />
                  </a>
                  <a
                    (click)="closeMenu()"
                    class="hover:scale-150"
                    href="//www.facebook.com/thekidscapitol"
                    target="_blank"
                    title="Visit our Facebook account">
                    <img
                      height="25"
                      width="25"
                      src="../../../assets/images/facebook.png"
                      alt="Facebook logo" />
                  </a>
                  <a
                    (click)="closeMenu()"
                    class="hover:scale-150"
                    href="//www.youtube.com/@ThekidsCapitol"
                    target="_blank"
                    title="Visit our Youtube account">
                    <img
                      height="26"
                      width="26"
                      src="../../../assets/images/YouTube.png"
                      alt="Youtube logo" />
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
