import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { SharedService } from "src/app/services/shared.service";
import { WebSocketService } from "src/app/services/web-socket.service";

@Component({
  selector: "app-attractions-page",
  templateUrl: "./attractions-page.component.html",
  styleUrls: ["./attractions-page.component.css"],
})
export class AttractionsPageComponent {
  whatWeOfferData: any;
  whatsInsideData: any;

  constructor(
    private webSocketService: WebSocketService,
    private sharedService: SharedService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle("The Kids Capitol Attractions");
    this.meta.updateTag({
      name: "description",
      content: "Attractions page of The Kids Capitol.",
    });
    this.meta.updateTag({ name: "keywords", content: this.sharedService.metaDataContent() });
    this.getAttractions();
  }

  getAttractions() {
    let url = "../../../assets/json/attractions.json";
    this.webSocketService.getObjects(url).subscribe(
      (data) => {
        data.map((val: any) => {
          if (val.whatWeOffer) {
            this.whatWeOfferData = val.whatWeOffer;
          }

          if (val.whatsInside) {
            this.whatsInsideData = val.whatsInside;
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  redirectToGallery(url: any) {
    this.sharedService.redirectToPage(url);
  }
}
