import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import { NgForm } from "@angular/forms";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import { Modal } from "flowbite";

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.css']
})
export class GetInTouchComponent {
  //emailJs
  emailJsServiceId: string = "service_7p2ywpt";
  emailJsTemplateId: string = "template_3a3vhfp";
  emailJsPublicKey: string = "BHlSvo91ChlY3_zvS";

  @ViewChild("modal") modalElementRef!: ElementRef;

  modal: any;
  customer = new Customer("", "", "", "Message from a customer", "", "");
  submitted: boolean = false;
  isFormSubmissionInProgress: boolean = false;
  scrollFromTop: number = 0;
  message: string = "";
  headerMessage: string = "";

  constructor() {
    this.customer.event = "Book a Birthday Party";
    this.message = `<p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
          Thank you for reaching out to us. We will get in touch with you at the
          earliest.
          <br />
          Thank you!
        </p>`;
    this.headerMessage = "Message Received!";
  }

  ngAfterViewInit() {
    this.modal = new Modal(this.modalElementRef.nativeElement);
  }

  onSubmit(event: Event, form: NgForm) {
    if (form.invalid) {
      return;
    } else {
      this.customer.subject = this.customer.subject + " - " + this.customer.event;
      this.submitted = true;
      this.isFormSubmissionInProgress = true;
      event.preventDefault();
      console.log("form submitted");
      emailjs
        .send(
          this.emailJsServiceId,
          this.emailJsTemplateId,
          {
            from_name: this.customer.name,
            to_name: "The Kids Capitol",
            subject: this.customer.subject,
            message: `Phone Number: ${this.customer.phone}, Email: ${this.customer.email} Message: ${this.customer.message}`,
            reply_to: this.customer.email,
          },
          this.emailJsPublicKey
        )
        .then(
          (result: EmailJSResponseStatus) => {
            console.log(result);
            console.log(result.text);
            this.submitted = false;
            this.isFormSubmissionInProgress = false;
            this.message = `<p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            Thank you for reaching out to us. We will get in touch with you at the
            earliest.
            <br />
            Thank you!
          </p>`;
            this.headerMessage = "Message Received!";
            this.toggleModal();
            this._resetForm(form);
          },
          (error) => {
            this.submitted = false;
            this.isFormSubmissionInProgress = false;
            console.log(error.text);

            this.message = ` <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            Something went wrong at our end.<br> Please reach out to us by sending an email to <a
            href="mailto:thekidscapitol@gmail.com"
            class="inline-flex items-center text-blue-600 hover:underline">
            thekidscapitol@gmail.com
          </a>.
            You can also call us on <b>+91 7736572227.</b>
            <br />
            Thank you!
          </p>`;
            this.headerMessage = "Ops!";
            this.toggleModal();
          }
        );
    }
  }

  toggleModal() {
    this.modal.toggle();
  }

  _resetForm(form: NgForm) {
    setTimeout(() => {
      form.resetForm();
    }, 200);
    setTimeout(() => {
      this.customer.event = "Book a Birthday Party";
    }, 500);
  }

}

export class Customer {
  constructor(
    public name: string,
    public email: string,
    public phone: string,
    public subject: string,
    public message: string,
    public event: String
  ) { }
}
