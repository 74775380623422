import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HomePageComponent } from "./home-page/home-page.component";
import { AboutPageComponent } from "./about-page/about-page.component";
import { AttractionsPageComponent } from "./attractions-page/attractions-page.component";
import { PricingPageComponent } from "./pricing-page/pricing-page.component";
import { FoodCapitolCafePageComponent } from "./food-capitol-cafe-page/food-capitol-cafe-page.component";
import { FranchisePageComponent } from "./franchise-page/franchise-page.component";
import { ContactPageComponent } from "./contact-page/contact-page.component";
import { UnAuthorizedPageComponent } from "./un-authorized-page/un-authorized-page.component";
import { GalleryPageComponent } from "./gallery-page/gallery-page.component";
import { TestimonialsPageComponent } from "./testimonials-page/testimonials-page.component";
import { RouterModule } from "@angular/router";
import { NewsRoomPageComponent } from "./news-room-page/news-room-page.component";
import { EventsWorkshopsPageComponent } from "./events-workshops-page/events-workshops-page.component";
import { TermsAndConditionsPageComponent } from "./terms-and-conditions-page/terms-and-conditions-page.component";
import { PrivacyPolicyPageComponent } from "./privacy-policy-page/privacy-policy-page.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [
    HomePageComponent,
    AboutPageComponent,
    AttractionsPageComponent,
    PricingPageComponent,
    FoodCapitolCafePageComponent,
    FranchisePageComponent,
    ContactPageComponent,
    UnAuthorizedPageComponent,
    GalleryPageComponent,
    TestimonialsPageComponent,
    NewsRoomPageComponent,
    EventsWorkshopsPageComponent,
    TermsAndConditionsPageComponent,
    PrivacyPolicyPageComponent,
  ],
  imports: [CommonModule, FormsModule, RouterModule, SharedModule],
  exports: [
    HomePageComponent,
    AboutPageComponent,
    AttractionsPageComponent,
    PricingPageComponent,
    FoodCapitolCafePageComponent,
    FranchisePageComponent,
    ContactPageComponent,
    GalleryPageComponent,
    TestimonialsPageComponent,
    NewsRoomPageComponent,
    EventsWorkshopsPageComponent,
    TermsAndConditionsPageComponent,
    PrivacyPolicyPageComponent,
  ],
})
export class PagesModule {}
