import { NgModule } from "@angular/core";
import { RouterModule, Routes, provideRouter } from "@angular/router";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { AboutPageComponent } from "./pages/about-page/about-page.component";
import { AttractionsPageComponent } from "./pages/attractions-page/attractions-page.component";
import { ContactPageComponent } from "./pages/contact-page/contact-page.component";
import { FranchisePageComponent } from "./pages/franchise-page/franchise-page.component";
import { FoodCapitolCafePageComponent } from "./pages/food-capitol-cafe-page/food-capitol-cafe-page.component";
import { PricingPageComponent } from "./pages/pricing-page/pricing-page.component";
import { UnAuthorizedPageComponent } from "./pages/un-authorized-page/un-authorized-page.component";
import { GalleryPageComponent } from "./pages/gallery-page/gallery-page.component";
import { TestimonialsPageComponent } from "./pages/testimonials-page/testimonials-page.component";
import { provideHttpClient } from "@angular/common/http";
import { NewsRoomPageComponent } from "./pages/news-room-page/news-room-page.component";
import { EventsWorkshopsPageComponent } from "./pages/events-workshops-page/events-workshops-page.component";
import { TermsAndConditionsPageComponent } from "./pages/terms-and-conditions-page/terms-and-conditions-page.component";
import { PrivacyPolicyPageComponent } from "./pages/privacy-policy-page/privacy-policy-page.component";

const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "home", component: HomePageComponent },
  { path: "about", component: AboutPageComponent },
  { path: "attractions", component: AttractionsPageComponent },
  { path: "contact", component: ContactPageComponent },
  { path: "franchise", component: FranchisePageComponent },
  { path: "the-food-capitol-cafe", component: FoodCapitolCafePageComponent },
  { path: "pricing", component: PricingPageComponent },
  { path: "gallery", component: GalleryPageComponent },
  { path: "testimonials", component: TestimonialsPageComponent },
  { path: "news-room", component: NewsRoomPageComponent },
  { path: "events-workshops", component: EventsWorkshopsPageComponent },
  { path: "terms-and-conditions", component: TermsAndConditionsPageComponent },
  { path: "privacy-policy", component: PrivacyPolicyPageComponent },
  { path: "un-authorized", component: UnAuthorizedPageComponent },
  { path: "**", redirectTo: "/un-authorized" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
    }),
  ],
  exports: [RouterModule],
  providers: [provideRouter(routes), provideHttpClient()],
})
export class AppRoutingModule {}
