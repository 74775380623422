import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { SharedService } from "src/app/services/shared.service";
import { WebSocketService } from "src/app/services/web-socket.service";

@Component({
  selector: "app-events-workshops-page",
  templateUrl: "./events-workshops-page.component.html",
  styleUrls: ["./events-workshops-page.component.css"],
})
export class EventsWorkshopsPageComponent {
  eventsAndCelebrations: any;
  constructor(
    private webSocketService: WebSocketService,
    private sharedService: SharedService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle("The Kids Capitol Event and Workshops");
    this.meta.updateTag({
      name: "description",
      content: `Events and Workshops page of The Kids Capitol. Stay informed about our latest events and workshops.
        Check out our event listings and register today to reserve your spot!`,
    });
    this.meta.updateTag({
      name: "keywords",
      content:
        "Events, Workshops, Upcoming events, Workshop schedule, Event calendar, Event registration, Workshop registration, Event details, Workshop details, Event listings.",
    });
    this.getEventsAndCelebrations();
  }

  getEventsAndCelebrations() {
    let url = "../../../assets/json/events-celebrations.json";
    this.webSocketService.getObjects(url).subscribe(
      (data) => {
        this.eventsAndCelebrations = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  redirectToGallery() {
    this.sharedService.redirectToPage("/gallery");
  }
}
