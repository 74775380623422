import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import { Modal } from "flowbite";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements AfterViewInit {
  comingSoon: boolean = false;
  showGallery: boolean = false;
  customer = new Customer("", "", "", "Message from a customer", "");
  submitted: boolean = false;
  isFormSubmissionInProgress: boolean = false;
  scrollFromTop: number = 0;

  //emailJs
  emailJsServiceId: string = "service_7p2ywpt";
  emailJsTemplateId: string = "template_3a3vhfp";
  emailJsPublicKey: string = "BHlSvo91ChlY3_zvS";

  modal: any;
  @ViewChild("modal") modalElementRef!: ElementRef;

  services: any[];
  message: string = "";
  headerMessage: string = "";

  ngAfterViewInit() {
    this.modal = new Modal(this.modalElementRef.nativeElement);
  }

  constructor() {
    this.message = ` <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
          Thank you for reaching out to us. We will get in touch with you at the
          earliest.
          <br />
          Thank you!
        </p>`;
    this.headerMessage = "Message Received!";

    this.services = [
      {
        name: "Exciting Play Areas",
        description:
          " Our play zone boasts a wide range of play areas designed to cater to children of all ages and interests. From vibrant ball pits and adventurous climbing structures to interactive games and imaginative play corners, there's something for everyone.",
        imageSrc: "../../../assets/images/play-zone-1.jpg",
      },
      {
        name: "Enriching Activities",
        description:
          "We understand the importance of holistic development, which is why we offer a variety of enriching activities. From arts and crafts to storytelling sessions, music and dance, and educational workshops, we ensure that every child's talents and interests are nurtured.",
        imageSrc: "../../../assets/images/paint.jpg",
      },
      {
        name: "Parent-Child Bonding",
        description:
          "We believe in fostering strong parent-child relationships. Our play zone provides designated areas for parents to actively engage with their children and share moments of joy together.",
        imageSrc: "../../../assets/images/parent-child.jpg",
      },
      {
        name: "Themed Birthday Parties",
        description: `Celebrate your child's special day in style! We offer themed birthday party packages that include exclusive use of our play zone, personalized decorations, exciting games, delicious food, and a dedicated party host to ensure an unforgettable experience.<br> 
          Themed birthday parties - For magical celebrations where your Princesses reign and Superheroes amaze`,
        imageSrc: "../../../assets/images/birthday.jpg",
      },
      {
        name: "Cafe - The Food Capitol",
        description:
          "While the kids play, parents can relax and recharge at our cozy cafe. We offer a wide selection of delicious snacks and light meals. Our seating area provides a comfortable space for parents to unwind, catch up with friends, or even get some work done while keeping an eye on their little ones.",
        imageSrc: "../../../assets/images/cafe.jpg",
      },
      {
        name: "Fun Filled School Trips",
        description:
          "Let your students enjoy team-building activities that promote collaboration, communication, and problem-solving skills. Students can engage in group challenges, trust-building exercises, and cooperative games, fostering a sense of camaraderie and teamwork..",
        imageSrc: "../../../assets/images/school-bus.jpg",
      },

      {
        name: "Safety First",
        description:
          "The safety of your child is our top priority. Our play zone is equipped with state-of-the-art safety measures, including padded flooring, secure entry and exit points, and vigilant staff members who are trained to ensure a safe and enjoyable experience for all.",
        imageSrc: "../../../assets/images/safety.jpg",
      },
      // {
      //   name: "Membership Options",
      //   description:
      //     "We offer flexible membership options for families who wish to enjoy our play zone regularly. Members receive exclusive benefits, discounts on activities, and priority access to special events. We look forward to welcoming you and your little ones to our Kids Play zone, where laughter, learning, and fun go hand in hand. Come and join us for a world of imagination and endless play possibilities!",
      //   imageSrc: "../../../assets/images/play-zone-1.jpg",
      // },
    ];
  }

  onSubmit(event: Event, form: NgForm) {
    this.submitted = true;
    this.isFormSubmissionInProgress = true;
    event.preventDefault();
    console.log("form submitted");
    emailjs
      .send(
        this.emailJsServiceId,
        this.emailJsTemplateId,
        {
          from_name: this.customer.name,
          to_name: "The Kids Capitol",
          subject: this.customer.subject,
          message: `Phone Number: ${this.customer.phone}, Email: ${this.customer.email} Message: ${this.customer.message}`,
          reply_to: this.customer.email,
        },
        this.emailJsPublicKey
      )
      .then(
        (result: EmailJSResponseStatus) => {
          console.log(result);
          console.log(result.text);
          this.submitted = false;
          this.isFormSubmissionInProgress = false;
          // form.reset();
          this.message = `<p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            Thank you for reaching out to us. We will get in touch with you at the
            earliest.
            <br />
            Thank you!
          </p>`;
          this.headerMessage = "Message Received!";
          this.toggleModal();
        },
        (error) => {
          this.submitted = false;
          this.isFormSubmissionInProgress = false;
          console.log(error.text);

          this.message = ` <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            Something went wrong at our end.<br> Please reach out to us by sending an email to <a
            href="mailto:thekidscapitol@gmail.com"
            class="inline-flex items-center text-blue-600 hover:underline">
            thekidscapitol@gmail.com
          </a>.
            You can also call us on <b>+91 7736572227.</b>
            <br />
            Thank you!
          </p>`;
          this.headerMessage = "Ops!";
          this.toggleModal();
        }
      );
  }

  toggleModal() {
    this.modal.toggle();
  }
}

export class Customer {
  constructor(
    public name: string,
    public email: string,
    public phone: string,
    public subject: string,
    public message: string
  ) {}
}
