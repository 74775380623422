<div class="m-auto h-full max-w-7xl px-7 pb-16 lg:px-0 lg:pb-32">
  <div
    class="grid grid-cols-1 gap-4 font-poppins md:grid-cols-2 lg:grid-cols-4">
    <!-- <div
      (click)="redirectToUrl('/attractions')" -->
    <div
      class="flex transform animate-slideAndAppearFromBottom cursor-pointer flex-col content-center justify-between rounded-2xl bg-white p-8 transition-all duration-500 ease-in-out hover:shadow-lg"
      style="height: 500px">
      <div class="flex flex-col">
        <div class="mb-5">
          <img
            height="100"
            width="100"
            src="../../../assets/images/About/icons/play-area.png"
            alt="Play area" />
          <!-- <app-svg-icon
            [icon]="'playFan'"
            [iconHeight]="75"
            [iconWidth]="75"></app-svg-icon> -->
        </div>
        <h3 class="mb-6 text-2xl font-bold text-gray-700">
          Exciting Play Area
        </h3>
        <p class="mb-4 text-sm font-medium leading-6 text-gray-500">
          Our play zone boasts a wide range of play areas designed to cater to
          children of all ages and interests. From vibrant ball pits and
          adventurous climbing structures to interactive games and imaginative
          play corners, there's something for everyone.
        </p>
      </div>
      <!-- <div>
        <p class="text-color-primary">
          View More
          <i class="fa-solid fa-arrow-right-long ml-2"></i>
        </p>
      </div> -->
    </div>

    <!-- <div
      (click)="redirectToUrl('/pricing')" -->
    <div
      class="flex transform animate-slideAndAppearFromBottom cursor-pointer flex-col content-center justify-between rounded-2xl bg-white p-8 transition-all duration-500 ease-in-out hover:shadow-lg">
      <div class="flex flex-col">
        <div class="mb-5">
          <img
            height="100"
            width="100"
            src="../../../assets/images/About/icons/birthday.png"
            alt="Birthday Party" />
          <!-- <app-svg-icon
            [icon]="'partyPlay'"
            [iconHeight]="75"
            [iconWidth]="75"></app-svg-icon> -->
        </div>
        <h3 class="mb-6 text-2xl font-bold text-gray-700">Birthday Parties</h3>
        <p class="mb-4 text-sm font-medium leading-6 text-gray-500">
          Celebrate your child's special day in style! We offer themed birthday
          party packages that include exclusive use of our party hall, play
          zone, personalized decorations, exciting games, delicious food to
          ensure an unforgettable experience. Themed birthday parties - For
          magical celebrations where your Princesses reign and Superheroes amaze
        </p>
      </div>
      <!-- <div>
        <p class="text-color-primary">
          View More
          <i class="fa-solid fa-arrow-right-long ml-2"></i>
        </p>
      </div> -->
    </div>

    <!-- <div
      (click)="redirectToUrl('/the-food-capitol-cafe')" -->
    <div
      class="flex transform animate-slideAndAppearFromBottom cursor-pointer flex-col content-center justify-between rounded-2xl bg-white p-8 transition-all duration-500 ease-in-out hover:shadow-lg">
      <div class="flex flex-col">
        <div class="mb-5">
          <img
            height="100"
            width="100"
            src="../../../assets/images/About/icons/school-trip.png"
            alt="School Trip" />
          <!-- <app-svg-icon
            [icon]="'cup'"
            [iconHeight]="75"
            [iconWidth]="75"></app-svg-icon> -->
        </div>
        <h3 class="mb-6 text-2xl font-bold text-gray-700">
          Fun Filled School Trips
        </h3>
        <p class="mb-4 text-sm font-medium leading-6 text-gray-500">
          Let your students enjoy team-building activities that promote
          collaboration, communication, and problem-solving skills. Students can
          engage in group challenges, trust-building exercises, and cooperative
          games, fostering a sense of camaraderie and teamwork..
        </p>
      </div>
      <!-- <div>
        <p class="text-color-primary">
          View More
          <i class="fa-solid fa-arrow-right-long ml-2"></i>
        </p>
      </div> -->
    </div>

    <!-- <div
      (click)="redirectToUrl('/contact')" -->
    <div
      class="flex transform animate-slideAndAppearFromBottom cursor-pointer flex-col content-center justify-between rounded-2xl bg-white p-8 transition-all duration-500 ease-in-out hover:shadow-lg">
      <div class="flex flex-col">
        <div class="mb-5">
          <img
            height="100"
            width="100"
            src="../../../assets/images/About/icons/private-play.png"
            alt="Private Play" />
          <!-- <app-svg-icon
            [icon]="'ball'"
            [iconHeight]="75"
            [iconWidth]="75">
          </app-svg-icon> -->
        </div>
        <h3 class="mb-6 text-2xl font-bold text-gray-700">Private Play</h3>
        <p class="mb-4 text-sm font-medium leading-6 text-gray-500">
          We offer a Private Play area just for you and your friends. We need a
          minimum of 50 kids to book the entire area for an Exclusive private
          party with no walkins for that period.
        </p>
      </div>
      <!-- <div>
        <p class="text-color-primary">
          View More
          <i class="fa-solid fa-arrow-right-long ml-2"></i>
        </p>
      </div> -->
    </div>

    <!-- <div
      (click)="redirectToUrl('/the-food-capitol-cafe')" -->
    <div
      class="flex transform animate-slideAndAppearFromBottom cursor-pointer flex-col content-center justify-between rounded-2xl bg-white p-8 transition-all duration-500 ease-in-out hover:shadow-lg">
      <div class="relative bottom-3 flex flex-col">
        <div>
          <img
            height="200"
            width="200"
            src="../../../assets/images/About/icons/the-food-capitol.png"
            alt="The Food Capitol" />
          <!-- <app-svg-icon
            [icon]="'cup'"
            [iconHeight]="75"
            [iconWidth]="75">
          </app-svg-icon> -->
        </div>
        <h3 class="mb-6 text-2xl font-bold text-gray-700">
          Cafe - The Food Capitol
        </h3>
        <p class="mb-4 text-sm font-medium leading-6 text-gray-500">
          While the kids play, parents can relax and recharge at our cozy cafe.
          We offer a wide selection of delicious snacks and light meals. Our
          seating area provides a comfortable space for parents to unwind, catch
          up with friends, or even get some work done while keeping an eye on
          their little ones.
        </p>
      </div>
      <!-- <div>
        <p class="text-color-primary">
          View More
          <i class="fa-solid fa-arrow-right-long ml-2"></i>
        </p>
      </div> -->
    </div>

    <!-- <div
      (click)="redirectToUrl('/the-food-capitol-cafe')" -->
    <div
      class="flex transform animate-slideAndAppearFromBottom cursor-pointer flex-col content-center justify-between rounded-2xl bg-white p-8 transition-all duration-500 ease-in-out hover:shadow-lg">
      <div class="flex flex-col">
        <div class="mb-5">
          <img
            height="100"
            width="100"
            src="../../../assets/images/About/icons/safety.png"
            alt="Safety First" />
          <!-- <app-svg-icon
            [icon]="'cup'"
            [iconHeight]="75"
            [iconWidth]="75"></app-svg-icon> -->
        </div>
        <h3 class="mb-6 text-2xl font-bold text-gray-700">Safety First</h3>
        <p class="mb-4 text-sm font-medium leading-6 text-gray-500">
          The safety of your child is our top priority. Our play zone is
          equipped with state-of-the-art safety measures, including padded
          flooring, secure entry and exit points, and vigilant staff members who
          are trained to ensure a safe and enjoyable experience for all.
        </p>
      </div>
      <!-- <div>
        <p class="text-color-primary">
          View More
          <i class="fa-solid fa-arrow-right-long ml-2"></i>
        </p>
      </div> -->
    </div>

    <!-- <div
      (click)="redirectToUrl('/the-food-capitol-cafe')" -->
    <div
      class="flex transform animate-slideAndAppearFromBottom cursor-pointer flex-col content-center justify-between rounded-2xl bg-white p-8 transition-all duration-500 ease-in-out hover:shadow-lg">
      <div class="flex flex-col">
        <div class="mb-5">
          <img
            height="100"
            width="100"
            src="../../../assets/images/About/icons/parent-child-bonding.png"
            alt="Parent-Child Bonding" />
          <!-- <app-svg-icon
            [icon]="'cup'"
            [iconHeight]="75"
            [iconWidth]="75"></app-svg-icon> -->
        </div>
        <h3 class="mb-6 text-2xl font-bold text-gray-700">
          Parent-Child Bonding
        </h3>
        <p class="mb-4 text-sm font-medium leading-6 text-gray-500">
          We believe in fostering strong parent-child relationships. Our play
          zone provides designated areas for parents to actively engage with
          their children and share moments of joy together.
        </p>
      </div>
      <!-- <div>
        <p class="text-color-primary">
          View More
          <i class="fa-solid fa-arrow-right-long ml-2"></i>
        </p>
      </div> -->
    </div>
  </div>
</div>
