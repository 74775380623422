<div>
  <form
    #contactForm="ngForm"
    name="contactForm"
    (ngSubmit)="onSubmit($event, contactForm)"
    novalidate>
    <div class="mb-5">
      <input
        #fullName="ngModel"
        [(ngModel)]="customer.name"
        type="text"
        name="fullName"
        class="block w-full rounded-full border border-gray-300 bg-gray-50 p-3.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        placeholder="Full Name"
        [disabled]="isFormSubmissionInProgress"
        required />
      <div
        class="pl-4 pt-2 text-sm text-orange-400"
        *ngIf="
          (fullName.touched || contactForm.submitted) &&
          (fullName.errors?.required || fullName.invalid)
        ">
        Name is required
      </div>
    </div>
    <div class="mb-5">
      <input
        #email="ngModel"
        [(ngModel)]="customer.email"
        type="email"
        name="email"
        class="block w-full rounded-full border border-gray-300 bg-gray-50 p-3.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        placeholder="Email"
        email
        [disabled]="isFormSubmissionInProgress"
        required />
      <div
        class="pl-4 pt-2 text-sm text-orange-400"
        *ngIf="(email.touched || contactForm.submitted) && email.invalid">
        <div *ngIf="email.errors?.required && !email.errors?.email">
          Email is required
        </div>
        <div *ngIf="email.errors?.email && !email.errors?.required">
          Invalid email
        </div>
      </div>
    </div>
    <div class="mb-5">
      <input
        #contactNumber="ngModel"
        [(ngModel)]="customer.phone"
        type="tel"
        name="contactNumber"
        class="block w-full rounded-full border border-gray-300 bg-gray-50 p-3.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        placeholder="Contact Number"
        [disabled]="isFormSubmissionInProgress"
        required />
      <div
        class="pl-4 pt-2 text-sm text-orange-400"
        *ngIf="
          (contactNumber.touched || contactForm.submitted) &&
          (contactNumber.errors?.required || contactNumber.invalid)
        ">
        Contact number is required
      </div>
    </div>
    <div class="mb-5">
      <label for="selectEvents"></label>
      <select
        [(ngModel)]="customer.event"
        id="selectEvents"
        name="selectEvents"
        class="block w-full rounded-full border border-gray-300 bg-gray-50 p-3.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        placeholder="Select Events"
        [disabled]="isFormSubmissionInProgress"
        required>
        <option value="Book a Birthday Party">Book a birthday party</option>
        <option value="Book a school trip">Book a school trip</option>
        <option value="Book a play date">Book a play date</option>
        <option value="Book an event">Book an event</option>
        <option value="Apply for a job">Apply for a job</option>
      </select>
    </div>
    <div class="relative mb-5">
      <textarea
        #customerMessage="ngModel"
        name="customerMessage"
        rows="4"
        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        placeholder="Ask Anything"
        [(ngModel)]="customer.message"
        [disabled]="isFormSubmissionInProgress"
        required></textarea>
      <div
        class="pl-4 pt-2 text-sm text-orange-400"
        *ngIf="
          (customerMessage.touched || contactForm.submitted) &&
          (customerMessage.errors?.required || customerMessage.invalid)
        ">
        Message is required
      </div>
    </div>
    <div
      [ngClass]="{
        'sm:w-1/3': !isFormSubmissionInProgress,
        'sm:w-1/2': isFormSubmissionInProgress
      }">
      <button
        type="submit"
        [ngClass]="{
          'pointer-events-auto cursor-pointer p-4 opacity-100':
            !isFormSubmissionInProgress,
          'pointer-events-none cursor-not-allowed p-3 opacity-50':
            isFormSubmissionInProgress
        }"
        class="w-full transform rounded-full bg-color-primary font-bold text-white transition-all duration-500 ease-in-out hover:translate-y-[-4px] hover:shadow-xl"
        style="border-top-right-radius: 0%"
        [disabled]="isFormSubmissionInProgress">
        <span *ngIf="!isFormSubmissionInProgress">Get Quote</span>
        <span
          *ngIf="isFormSubmissionInProgress"
          class="flex items-center justify-center">
          <span class="mr-2 block">Submitting...</span>
          <span class="inline-block">
            <img
              src="./assets/images/loading-indicator.svg"
              class="h-10"
              alt="Loading" />
          </span>
        </span>
      </button>
    </div>
  </form>
</div>

<!-- Modal -->
<div
  id="modal"
  #modal
  tabindex="-1"
  aria-hidden="true"
  class="fixed left-0 right-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0">
  <div class="relative max-h-full w-full max-w-2xl">
    <!-- Modal content -->
    <div class="relative rounded-lg bg-white shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div class="flex items-start justify-between rounded-t border-b p-4">
        <h3>{{ headerMessage }}</h3>
        <button
          (click)="toggleModal()"
          type="button"
          class="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white">
          <svg
            class="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>
      <!-- Modal body -->
      <div class="space-y-6 p-6" [innerHTML]="message"></div>
    </div>
  </div>
</div>
