<svg
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  [attr.viewBox]="iconViewBox"
  [attr.height]="iconHeight"
  [attr.width]="iconHeight"
  [attr.class]="iconClass">
  <ng-container [ngSwitch]="icon">
    <ng-container *ngSwitchCase="'child'">
      <g data-name="&lt;Group&gt;">
        <circle
          data-name="&lt;Path&gt;"
          class="shape-fill-primary"
          cx="49.26"
          cy="31.25"
          r="12.1"
          transform="rotate(-.68 49.527 31.47)" />
        <g data-name="&lt;Group&gt;">
          <path
            data-name="&lt;Path&gt;"
            class="shape-fill-dark"
            d="M43.67 54.85a8.55 8.55 0 0 0-17.1.2l5.19-.05a3.36 3.36 0 0 1 6.72-.08ZM64.8 46a8.56 8.56 0 0 0-8.45 8.65l5.19-.06a3.36 3.36 0 0 1 6.72-.08l5.19-.06A8.56 8.56 0 0 0 64.8 46M50.19 70a9.37 9.37 0 0 1-8.08-4.5l-4.44 2.69a14.51 14.51 0 0 0 25-.3l-4.5-2.58a9.36 9.36 0 0 1-8 4.69Z" />
          <path
            data-name="&lt;Compound Path&gt;"
            class="shape-fill-dark"
            d="M90.81 45.09a11.47 11.47 0 0 0-6-3.1A36.78 36.78 0 0 0 49.56 17h-1.74a7.59 7.59 0 0 1-7.67-7.49L35 9.62a12.7 12.7 0 0 0 2.59 7.55h-1.64a7.59 7.59 0 0 1-7.71-7.47l-5.19.06a12.79 12.79 0 0 0 8.9 12 36.72 36.72 0 0 0-17 21.05 11.53 11.53 0 0 0 .27 22.65 36.72 36.72 0 0 0 69.86-.83 11.53 11.53 0 0 0 5.75-19.55ZM83 59.67l-2 .06-.49 1.89a31.53 31.53 0 0 1-60.85.73l-.53-1.87h-1.94a6.37 6.37 0 0 1-6.29-6.28 6.33 6.33 0 0 1 6.1-6.41l2-.06.49-1.89a31.57 31.57 0 0 1 30.13-23.63 8.51 8.51 0 1 1-8.4 8.61l-5.22.06a13.7 13.7 0 1 0 26-6.3 31.54 31.54 0 0 1 18.33 20.54l.53 1.88h1.95A6.34 6.34 0 0 1 83 59.67" />
        </g>
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'socks'">
      <g data-name="&lt;Group&gt;">
        <path
          data-name="&lt;Path&gt;"
          class="shape-fill-primary"
          d="M71.28 85.62v-3.16a11.29 11.29 0 0 1 11.27-11.27h3.86a16.44 16.44 0 0 1-15.13 14.43M59.32 42.13h27.2v4.84h-27.2z" />
        <g data-name="&lt;Group&gt;">
          <path
            data-name="&lt;Compound Path&gt;"
            class="shape-fill-dark"
            d="M75.24 23.31V9.5H38.35v25A4.39 4.39 0 0 1 34 38.85h-6.52a18.84 18.84 0 0 0-2.11 37.56A18.88 18.88 0 0 0 43.61 90.5h26.5a21.28 21.28 0 0 0 21.25-21.25V23.31Zm11.28 14h-27.2v-9.15h5.84v4.49H70v-4.49h5.84v4.5h4.84v-4.5h5.85ZM54.48 33H43.19v-4.84h11.29Zm4.84 9.13h27.2V47h-27.2ZM49 14.34v4.34h4.84v-4.34h5.84v4.34h4.84v-4.34h5.88v9H43.19v-9ZM13.48 57.69a14 14 0 0 1 14-14H34a9.24 9.24 0 0 0 8.58-5.85h11.9v10.6a4.39 4.39 0 0 1-4.38 4.38h-6.49a18.86 18.86 0 0 0-18.84 18.6 14 14 0 0 1-11.29-13.73m16.12 14a14 14 0 0 1 14-14h6.5a9.24 9.24 0 0 0 8.58-5.85h27.84v14.51h-4a16.14 16.14 0 0 0-16.09 16.11v3.2H43.61a14 14 0 0 1-14-14Zm41.66 14v-3.23a11.29 11.29 0 0 1 11.28-11.27h3.86a16.44 16.44 0 0 1-15.13 14.43Z" />
        </g>
        <path
          data-name="&lt;Path&gt;"
          class="shape-fill-primary"
          d="M42.52 28.16h12.64V33H42.52z" />
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'pencil'">
      <path
        data-name="&lt;Path&gt;"
        class="shape-fill-primary"
        d="M56.61 35.45H76V69H56.61zM33.19 12.7h.33l8.4 14.3H24.79z" />
      <path
        data-name="&lt;Compound Path&gt;"
        class="shape-fill-dark"
        d="M30.79 9 20 27.41V91h27.4V27.41L36.58 9Zm2.75 4.8h.3l7.62 13H25.91Zm-8.76 55.42h17.81v5.61H24.78Zm0-4.8V31.58h17.81v32.83Zm0 21.78v-6.57h17.81v6.57ZM52.6 9v63.59L63.42 91h5.79L80 72.59V9Zm4.8 59.42V35.59h17.82v32.83Zm17.82-37.64H57.41v-5.6h17.81Zm0-17v6.57H57.41V13.8ZM66.46 86.2h-.3l-7.62-13h15.55Z" />
    </ng-container>

    <ng-container *ngSwitchCase="'head'">
      <path
        d="M79.77 52.64a30.23 30.23 0 0 0-58.83 0H19a32.23 32.23 0 1 1 62.73 0Z"
        class="shape-fill-primary" />
      <g data-name="&lt;Group&gt;">
        <path
          data-name="&lt;Compound Path&gt;"
          class="shape-fill-dark"
          d="M50 91a31.33 31.33 0 0 0 31-27.39 36 36 0 1 0-62 0A31.33 31.33 0 0 0 50 91m0-77.2a31.39 31.39 0 0 1 30.39 38.6 31.23 31.23 0 0 0-60.78 0A31.39 31.39 0 0 1 50 13.8m0 19.38a7.18 7.18 0 0 1 6.65 4.73c1.85 4.47.23 9.69-4.25 9.69H50a2.4 2.4 0 1 1 1.7-4.1l3.4-3.4A7.21 7.21 0 1 0 50 52.4h2.4c4.77 0 9.06-3.42 9.72-9.24a14.6 14.6 0 0 0-1.28-7.65A26.42 26.42 0 1 1 50 33.18" />
        <path
          data-name="&lt;Compound Path&gt;"
          class="shape-fill-dark"
          d="M50 81.23a7.22 7.22 0 0 0 6.79-4.8H62v-4.8h-5.21a7.21 7.21 0 0 0-13.59 0H38v4.8h5.22a7.22 7.22 0 0 0 6.78 4.8m0-9.61A2.4 2.4 0 1 1 47.6 74a2.41 2.41 0 0 1 2.4-2.38" />
        <path
          data-name="&lt;Path&gt;"
          class="shape-fill-dark"
          d="M38 57.21h4.8V62H38Zm19.21 0H62V62h-4.8Z" />
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'giftBox'">
      <g data-name="&lt;Group&gt;">
        <g data-name="&lt;Group&gt;">
          <path
            data-name="&lt;Compound Path&gt;"
            class="shape-fill-dark"
            d="M73.73 47.63H41.55l28.11-16.24a2.37 2.37 0 0 0 .87-3.24l-7.12-12.33a2.37 2.37 0 0 0-3.24-.82l-7.27 4.13a9.5 9.5 0 1 0-19 .18l.25 7.56L27.88 23A9.49 9.49 0 1 0 18 39.2l-7.35 4.23a2.37 2.37 0 0 0-.86 3.26L17.11 59a2.37 2.37 0 0 0 3.23.85l3.56-2.06v30.34a2.37 2.37 0 0 0 2.37 2.37h47.46a2.37 2.37 0 0 0 2.37-2.37V50a2.37 2.37 0 0 0-2.37-2.37m-26.1 4.75h4.75V68l-.7-.7a2.37 2.37 0 0 0-3.36 0l-.7.7ZM41 14.88a4.75 4.75 0 0 1 6.48 1.74A4.93 4.93 0 0 1 46.15 23l-7.22 4.16-.27-8A4.74 4.74 0 0 1 41 14.88M21.63 35.6a4.75 4.75 0 1 1 3.74-8.6L32 31.14l-7.47 4.3a4.7 4.7 0 0 1-2.9.16M20 54.59l-4.86-8.24 45.35-26.1 4.75 8.22Zm51.36 31.16H28.64V55.08l4.69-2.71h9.55v21.36a2.37 2.37 0 0 0 4.05 1.68L50 72.34l3.07 3.07a2.37 2.37 0 0 0 4.05-1.68V52.37h14.24z" />
          <path
            data-name="&lt;Path&gt;"
            class="shape-fill-dark"
            d="M88.13 38.13h-2.37v-2.37a2.37 2.37 0 1 0-4.75 0v2.37h-2.37a2.37 2.37 0 1 0 0 4.75H81v2.37a2.37 2.37 0 0 0 4.75 0v-2.37h2.37a2.37 2.37 0 1 0 0-4.75Z" />
          <circle
            data-name="&lt;Path&gt;"
            class="shape-fill-dark"
            cx="78.48"
            cy="26.27"
            r="2.37" />
          <circle
            data-name="&lt;Path&gt;"
            class="shape-fill-dark"
            cx="68.99"
            cy="40.51"
            r="2.37" />
        </g>
        <path
          data-name="&lt;Path&gt;"
          class="shape-fill-primary"
          d="M47.7 52.62h4.75v15.63l-.7-.7a2.37 2.37 0 0 0-3.36 0l-.7.7ZM20 54.58l-4.86-8.24 45.35-26.1 4.75 8.22Z" />
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'balloon'">
      <g data-name="&lt;Group&gt;">
        <path
          data-name="&lt;Compound Path&gt;"
          d="m30.86 56.49-2.11 7.07A2.37 2.37 0 0 0 31 66.61h2.37v3.76a10.4 10.4 0 0 0 3.07 7.41A6.13 6.13 0 0 1 38.13 82v6.14a2.37 2.37 0 0 0 4.75 0V82a10.9 10.9 0 0 0-3.07-7.57 5.7 5.7 0 0 1-1.68-4.05v-3.77h2.37a2.37 2.37 0 0 0 2.27-3.05l-2.11-7.07a20.2 20.2 0 0 0 7-3.39 21 21 0 0 0 11.68 8.15l-2.12 7.05a2.37 2.37 0 0 0 2.27 3.05h2.37v3.76a10.9 10.9 0 0 0 3.07 7.57 5.7 5.7 0 0 1 1.68 4.05v1.39a2.37 2.37 0 0 0 4.75 0v-1.38a10.4 10.4 0 0 0-3.07-7.41 6.13 6.13 0 0 1-1.68-4.21v-3.76H69a2.37 2.37 0 0 0 2.27-3.05l-2.11-7.07c9.42-2.47 16.45-11.89 16.45-23.1C85.6 25 76 14.25 64.24 14.25a19.88 19.88 0 0 0-11.94 4A20.55 20.55 0 0 0 35.76 9.5C24 9.5 14.4 20.22 14.4 33.39c0 11.21 7.04 20.61 16.46 23.1m3.35 5.38 1.42-4.75h.28l1.42 4.75Zm28.48 4.75 1.42-4.75h.28l1.42 4.75ZM64.24 19c9.16 0 16.61 8.59 16.61 19.14s-7.45 19-16.61 19a15.9 15.9 0 0 1-13.09-7.29 25.1 25.1 0 0 0 6-16.44 26 26 0 0 0-2.39-11A15.22 15.22 0 0 1 64.24 19m-28.48-4.75c9.16 0 16.61 8.59 16.61 19.14s-7.45 19-16.61 19-16.61-8.52-16.61-19 7.45-19.14 16.61-19.14"
          class="shape-fill-dark" />
        <path
          data-name="&lt;Path&gt;"
          d="M64.19 18.88c9.16 0 16.61 8.59 16.61 19.14S73.35 57 64.19 57a15.9 15.9 0 0 1-13.09-7.29 25.1 25.1 0 0 0 6-16.44 26 26 0 0 0-2.39-11 15.22 15.22 0 0 1 9.48-3.39"
          class="shape-fill-primary" />
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'sanitizeHands'">
      <g data-name="&lt;Group&gt;">
        <path
          data-name="&lt;Path&gt;"
          class="shape-fill-primary"
          d="M86 65.12a3.22 3.22 0 0 1-1.71 1.71L48.62 81.88a10.71 10.71 0 0 1-8.92-.25l-20.46-10V58.8l2.42-1.8A17.21 17.21 0 0 1 39 55l16.46 7.27A3.47 3.47 0 0 1 57.14 64a3.17 3.17 0 0 1-.06 2.4 3.44 3.44 0 0 1-1.77 1.64 3.16 3.16 0 0 1-2.4-.06L40.27 62.4a1.91 1.91 0 0 0-1.58 3.48l12.65 5.63a6.75 6.75 0 0 0 5.31.13c.2-.08.39-.17.57-.26a2 2 0 0 0 .5-.12L81.88 61a3.16 3.16 0 0 1 2.4 0A3.22 3.22 0 0 1 86 62.72a3.16 3.16 0 0 1 0 2.4" />
        <g data-name="&lt;Group&gt;">
          <path
            data-name="&lt;Path&gt;"
            class="shape-fill-dark"
            d="M44.44 86.5a15 15 0 0 1-6.39-1.5L23 77.71a1.9 1.9 0 0 1 1.64-3.42l15.06 7.34a10.71 10.71 0 0 0 8.92.25l35.66-15.05A3.22 3.22 0 0 0 86 65.12a3.16 3.16 0 0 0 0-2.4A3.22 3.22 0 0 0 84.28 61a3.16 3.16 0 0 0-2.4 0L57.72 71.26a1.89 1.89 0 0 1-1.45-3.48l24.16-10.25a7 7 0 0 1 5.31-.06 6.87 6.87 0 0 1 3.79 3.73 7 7 0 0 1 .06 5.31 6.86 6.86 0 0 1-3.73 3.79L50.2 85.36a15.3 15.3 0 0 1-5.76 1.14" />
          <path
            data-name="&lt;Path&gt;"
            class="shape-fill-dark"
            d="M64.24 61.71a2 2 0 0 1-1.77-1.14 1.91 1.91 0 0 1 1-2.47l7.34-3.1a6.92 6.92 0 0 1 9.11 3.67 1.89 1.89 0 0 1-3.48 1.45 3.13 3.13 0 0 0-4.11-1.64L65 61.58a3.2 3.2 0 0 1-.76.13M17.5 82.07h-5.69a1.9 1.9 0 0 1 0-3.79h3.79V58a1.72 1.72 0 0 1 .57-1.33l.63-.63.19-.19L19.46 54a20.87 20.87 0 0 1 21.12-2.47L57 58.8a6.95 6.95 0 0 1-.32 12.84 6.75 6.75 0 0 1-5.31-.13l-12.68-5.63a1.91 1.91 0 1 1 1.58-3.48L52.92 68a3.17 3.17 0 0 0 2.4.06 3.45 3.45 0 0 0 1.77-1.64 3.17 3.17 0 0 0 .06-2.4 3.45 3.45 0 0 0-1.64-1.77L39.07 55a17.14 17.14 0 0 0-17.33 2l-2.34 1.8v21.37a1.9 1.9 0 0 1-1.9 1.9" />
        </g>
        <path
          data-name="&lt;Compound Path&gt;"
          class="shape-fill-dark"
          d="M56.08 38.17a9.06 9.06 0 0 1-7.72-13.72l6.07-10.06a2 2 0 0 1 3.29 0l6.07 10.06a9.06 9.06 0 0 1-7.72 13.72Zm0-19.1-4.49 7.4a5.05 5.05 0 0 0-.06 5.25 5.23 5.23 0 1 0 9-5.25Z" />
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'crossMark'">
      <g data-name="&lt;Group&gt;">
        <g data-name="&lt;Group&gt;">
          <path
            data-name="&lt;Path&gt;"
            class="shape-fill-primary"
            d="M84.31 73.82 61.59 51.18l22.64-22.73-10.76-10.72-22.64 22.73L28.1 17.82 17.38 28.57l22.73 22.65-22.64 22.73 10.75 10.71 22.65-22.72L73.6 84.58z" />
        </g>
        <path
          data-name="&lt;Compound Path&gt;"
          class="shape-fill-dark"
          d="M50 6a44 44 0 1 0 44 44A44 44 0 0 0 50 6m0 6a37.77 37.77 0 0 1 19.54 5.42l-18.72 18.8-19.41-19.34A37.75 37.75 0 0 1 50 12M16.56 32l19.3 19.23L17.5 69.67A37.87 37.87 0 0 1 16.56 32M33.1 84l17.78-17.82 17.25 17.19a37.84 37.84 0 0 1-35 .65Zm40.16-4-22.4-22.31-23 23.13a38.3 38.3 0 0 1-6.75-6.23l23.24-23.38-24.44-24.35a38.4 38.4 0 0 1 6.43-6.56l24.5 24.4L74.48 21a38.3 38.3 0 0 1 6.26 6.73l-23.4 23.45 22.45 22.37A38.4 38.4 0 0 1 73.26 80m-7.43-28.83L84 33a37.88 37.88 0 0 1-.76 35.49Z" />
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'shoe'">
      <g data-name="&lt;Group&gt;">
        <path
          data-name="&lt;Path&gt;"
          class="shape-fill-primary"
          d="M84.52 60.49a15.49 15.49 0 0 0-12.83 12.68H34.77v-20L46.46 64 57 52.72l-12.79-11.9L47.78 37l12.64 11.75L71 37.44l-10.69-10h24.21v33Z" />
        <g data-name="&lt;Group&gt;">
          <path
            data-name="&lt;Compound Path&gt;"
            class="shape-fill-dark"
            d="M70.45 22.34V10.5H54.29v11.9l-23.2 25a21.94 21.94 0 0 0-20.76 21.86V89.5h79.34V22.35Zm14.07 38.15a15.49 15.49 0 0 0-12.83 12.68H34.77v-20L46.46 64 57 52.72l-12.79-11.9L47.78 37l12.64 11.75L71 37.44l-10.69-10h24.21v33Zm0 5.25v7.43h-7.57a10.34 10.34 0 0 1 7.57-7.43M37.2 48.37l3.51-3.78 9 8.38-3.51 3.78ZM63.67 37.7l-3.51 3.77-8.88-8.27 3.51-3.78Zm1.63-22.05v6.68h-5.85v-6.68ZM15.48 69.26A16.79 16.79 0 0 1 29.62 52.7v20.47H15.48Zm69 15.09h-69v-6h69Z" />
        </g>
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'location'">
      <path
        data-name="&lt;Path&gt;"
        class="shape-fill-primary"
        d="M49.56 51.82a9.61 9.61 0 1 1 9.61-9.61 9.62 9.62 0 0 1-9.61 9.61" />
      <path
        data-name="&lt;Compound Path&gt;"
        class="shape-fill-dark"
        d="M49.28 57a15 15 0 1 1 15-15 15 15 0 0 1-15 15m0-24a9 9 0 1 0 9 9 9 9 0 0 0-9-9" />
      <path
        data-name="&lt;Compound Path&gt;"
        class="shape-fill-dark"
        d="M47.39 89.37a143.5 143.5 0 0 1-15-14.86C22 62.65 17 52 17 42.91V42a32.5 32.5 0 1 1 65 0v.91c0 9.11-5.27 19.74-15.6 31.6a134.5 134.5 0 0 1-15.22 14.86l-1.9 1.53Zm2.11-74A26.55 26.55 0 0 0 23 42v.91C23 58.53 43.11 77.52 49.33 83v.12l.17-.12C55.71 77.52 76 58.53 76 42.91V42a26.55 26.55 0 0 0-26.5-26.64Z" />
    </ng-container>

    <ng-container *ngSwitchCase="'alarm'">
      <path
        data-name="&lt;Path&gt;"
        class="shape-fill-primary"
        d="M83.48 34.85a45.4 45.4 0 0 0-13.53-14.6 10.15 10.15 0 0 1 13.53 14.6m-66.87.75a10.34 10.34 0 0 1 13.78-14.87A46.2 46.2 0 0 0 16.61 35.6" />
      <path
        data-name="&lt;Compound Path&gt;"
        class="shape-fill-dark"
        d="m74.31 90-3.16-6.33-.19.12a37.52 37.52 0 0 1-41.35 0l-.19-.12L26.3 90h-6.67l5-10.19-.11-.11-.7-.7A37.55 37.55 0 0 1 15 40.06l.06-.18-.18-.07a3 3 0 0 1-1-.65A15 15 0 0 1 35 18l.1.1.23.06A36.2 36.2 0 0 1 47 15.2V9h6v6.18h.33a37.6 37.6 0 0 1 11.85 3l.17.06.21-.21a15 15 0 0 1 21.17 21.13 3 3 0 0 1-1 .65l-.18.07.06.18A37.55 37.55 0 0 1 76.79 79l-.76.73-.11.11L81 90Zm-24-69a31.47 31.47 0 1 0 31.47 31.52A31.51 31.51 0 0 0 50.31 21m25.85-1.44a8.94 8.94 0 0 0-4.95 1.48l-.25.17.25.17a38 38 0 0 1 11.71 12.68l.15.26.18-.24a9 9 0 0 0-7.09-14.47Zm-51.7 0a9 9 0 0 0-7.09 14.47l.18.24.15-.26a37.9 37.9 0 0 1 11.71-12.58l.25-.17-.25-.17a8.94 8.94 0 0 0-4.95-1.48Z" />
      <path
        data-name="&lt;Path&gt;"
        class="shape-fill-dark"
        d="M47 53.76V29h6v22.28l10.41 10.27-4.31 4.23z" />
    </ng-container>

    <ng-container *ngSwitchCase="'playFan'">
      <g data-name="&lt;Group&gt;">
        <path
          data-name="&lt;Compound Path&gt;"
          class="shape-fill-dark"
          d="M84.76 12.41H49.43l-18.25 9.13L18.65 9v35.33l9.12 18.25-12.53 12.54h32.35V91h4.82V74.2L68.82 66l12.53 12.53V43.19l-9.12-18.24Zm-28.11 21.3a12 12 0 0 0-4.23-1.76V17.23h20.7zm-9.06-1.76a12 12 0 0 0-4.23 1.76l-8.58-8.58 12.81-6.41Zm-4.83 11.81A7.24 7.24 0 0 1 50 36.53c9.6.4 9.6 14.07 0 14.47a7.24 7.24 0 0 1-7.24-7.24m9.65 11.82a12 12 0 0 0 4.23-1.76l8.58 8.58-12.81 6.4ZM39.94 37.12a12 12 0 0 0-1.76 4.23H23.47v-20.7ZM25 46.18h13.18a12 12 0 0 0 1.76 4.23L31.37 59Zm18.39 7.64a12 12 0 0 0 4.23 1.76v14.71H26.88Zm16.7-3.41a12 12 0 0 0 1.76-4.23h14.68v20.7Zm15-9.06H61.82a12 12 0 0 0-1.76-4.23l8.58-8.58Z" />
        <path
          data-name="&lt;Path&gt;"
          class="shape-fill-primary"
          d="M56.64 33.71a12 12 0 0 0-4.23-1.76V17.23h20.71zm-16.7 3.41a12 12 0 0 0-1.76 4.23H23.47v-20.7Zm3.41 16.7a12 12 0 0 0 4.23 1.76v14.71h-20.7Zm16.71-3.41a12 12 0 0 0 1.76-4.23h14.71v20.7Z" />
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'partyPlay'">
      <path
        class="shape-fill-primary"
        d="M63 72.74v-5.8h15.85a1.91 1.91 0 0 0 1.9-1.9v-7.18l.62.16a6.91 6.91 0 0 1 5.18 6.69V65a7.72 7.72 0 0 1-7.71 7.71Z" />
      <g data-name="&lt;Group&gt;">
        <g data-name="&lt;Group&gt;">
          <path
            data-name="&lt;Compound Path&gt;"
            class="shape-fill-dark"
            d="M81.37 53.64A36.2 36.2 0 0 0 40.16 19l-28-9.82A2.4 2.4 0 0 0 9 11.8l4.4 25.87a2.4 2.4 0 0 0-.34.49A36.17 36.17 0 0 0 45.2 91a35.7 35.7 0 0 0 16.33-3.91 36.7 36.7 0 0 0 12.17-10H79a12 12 0 0 0 12-12v-.32a11.23 11.23 0 0 0-9.63-11.1ZM16.81 29l11-9.29L35 22.24 18.08 36.48Zm5.76-11.14-6.69 5.64-1.43-8.5ZM45.2 86.2A31.37 31.37 0 0 1 16 43.39c.15 0 24.69-19.65 24.69-19.65a31.39 31.39 0 0 1 34.9 38.91H52.37a13 13 0 0 0-2.7-3.39 2.4 2.4 0 0 0-3.21 3.58A8.22 8.22 0 0 1 46 75.47a2.4 2.4 0 1 0 2.93 3.81 13 13 0 0 0 2.24-2.21H67.3a31.1 31.1 0 0 1-22.1 9.13m8.38-13.93a12.8 12.8 0 0 0 .34-4.8h4.89v4.8Zm32.62-7.22a7.22 7.22 0 0 1-7.2 7.21H63.61v-4.8H79a2.4 2.4 0 0 0 2.4-2.4v-6.53a6.42 6.42 0 0 1 4.81 6.2Z" />
          <path
            data-name="&lt;Path&gt;"
            class="shape-fill-dark"
            d="M31.66 41.32a10.49 10.49 0 0 0-9.93 7.1A2.4 2.4 0 1 0 26.28 50 5.69 5.69 0 0 1 37 50a2.4 2.4 0 0 0 4.55-1.56 10.49 10.49 0 0 0-9.89-7.12M50.3 51.47a2.4 2.4 0 0 0 3-1.5 5.69 5.69 0 0 1 10.76 0 2.4 2.4 0 0 0 4.55-1.56 10.49 10.49 0 0 0-19.85 0 2.4 2.4 0 0 0 1.5 3Z" />
          <circle
            data-name="&lt;Path&gt;"
            class="shape-fill-dark"
            cx="51.89"
            cy="30.82"
            r="2.4"
            transform="rotate(-58.28 51.894 30.824)" />
          <path
            data-name="&lt;Path&gt;"
            class="shape-fill-dark"
            d="M29.47 62.56a2.4 2.4 0 0 0-3.31.78 2.39 2.39 0 0 1-2.44 1.06 2.4 2.4 0 1 0-.84 4.73A7.2 7.2 0 0 0 30.19 66l.06-.1a2.4 2.4 0 0 0-.78-3.31Z" />
        </g>
        <path
          data-name="&lt;Path&gt;"
          class="shape-fill-primary"
          d="m16.82 29.02 11.01-9.3 7.16 2.52-16.91 14.24zm5.75-11.15-6.69 5.64-1.43-8.49z" />
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'ball'">
      <g data-name="&lt;Group&gt;">
        <g data-name="&lt;Group&gt;">
          <path
            data-name="&lt;Compound Path&gt;"
            class="shape-fill-dark"
            d="M79.14 21a41.1 41.1 0 0 0-58.28 58 41.1 41.1 0 0 0 58.28-58m-3.41 3.41a36.2 36.2 0 0 1 7 9.84A16.5 16.5 0 0 0 71 33.83a21.3 21.3 0 0 0-4.77-4.78 16 16 0 0 0-.44-11.69 36 36 0 0 1 9.94 7.07Zm-7.14 15.12c.44 1.31.23 1.88.17 1.94s-.63.27-1.94-.17a13.4 13.4 0 0 1-4.88-3.24 13.4 13.4 0 0 1-3.24-4.88c-.44-1.31-.23-1.88-.17-1.94a1 1 0 0 1 .54-.1 4.5 4.5 0 0 1 1.4.27 14.8 14.8 0 0 1 8.12 8.12M58.9 14.91c2.93 4 4 8.54 3 11.89a7.77 7.77 0 0 0-4.67-.21 31.45 31.45 0 0 0-18.63-7.85 31.8 31.8 0 0 0-7.51.35 36.8 36.8 0 0 1 27.81-4.18M20.85 28.37a26.84 26.84 0 0 1 33 1.67 6 6 0 0 0-.18.9c-10.11.64-23.3 7.86-34.5 19.06a87 87 0 0 0-5.06 5.51 36.08 36.08 0 0 1 6.74-27.14m3.42 47.2a36 36 0 0 1-8.76-14.15 83 83 0 0 1 7.05-8C33.06 42.91 45.63 36 54.51 35.72a18.9 18.9 0 0 0 4 5.75 18.9 18.9 0 0 0 5.75 4c-.29 8.79-7.2 21.3-17.68 31.79a83 83 0 0 1-8 7.08 36 36 0 0 1-14.31-8.77m47.19 3.66a36.5 36.5 0 0 1-27 6.53c1.89-1.59 3.73-3.29 5.52-5.07C61.19 69.5 68.4 56.38 69.06 46.35a6 6 0 0 0 .91-.18 26 26 0 0 1 1.49 33.06m10-17.88a31.27 31.27 0 0 0-8-18.55 7.77 7.77 0 0 0-.21-4.66c3.39-1.06 8.05 0 12 2.94A36.25 36.25 0 0 1 81 68.88a30.3 30.3 0 0 0 .42-7.53Z" />
        </g>
        <path
          data-name="&lt;Path&gt;"
          class="shape-fill-primary"
          d="M75.67 24.36a36.2 36.2 0 0 1 7 9.84 16.5 16.5 0 0 0-11.79-.44A21.3 21.3 0 0 0 66.12 29a16 16 0 0 0-.44-11.69 36 36 0 0 1 9.99 7.05M20.79 28.3a26.84 26.84 0 0 1 33 1.67 6 6 0 0 0-.18.9c-10.11.64-23.3 7.86-34.5 19.06a87 87 0 0 0-5.06 5.51 36.08 36.08 0 0 1 6.74-27.14M71.4 79.16a36.5 36.5 0 0 1-27 6.53c1.89-1.59 3.73-3.29 5.52-5.07C61.13 69.43 68.34 56.31 69 46.28a6 6 0 0 0 .91-.18 26 26 0 0 1 1.49 33.06" />
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'cup'">
      <g data-name="&lt;Group&gt;">
        <path
          data-name="&lt;Compound Path&gt;"
          class="shape-fill-dark"
          d="M76.61 42.74v-4.81H13.77V50a31.46 31.46 0 0 0 14.76 26.57H9V79a12 12 0 0 0 12 12h48.4a12 12 0 0 0 12-12v-2.4H61.85a32 32 0 0 0 5.9-4.81h6.46A16.93 16.93 0 0 0 91 54.77v-12H76.61Zm-.41 38.64a7.23 7.23 0 0 1-6.8 4.81H21a7.23 7.23 0 0 1-6.8-4.81Zm-31-4.81A26.64 26.64 0 0 1 18.58 50v-7.26H71.8V50a26.64 26.64 0 0 1-26.61 26.57Zm41-21.8A12.12 12.12 0 0 1 74.21 67h-2.62a31.07 31.07 0 0 0 5-17v-2.4h9.62ZM34.62 9 32 13a3.38 3.38 0 0 1 0 5.62 8.34 8.34 0 0 0 0 13.78l2.67-4a3.53 3.53 0 0 1 0-5.78A8.19 8.19 0 0 0 34.62 9m12 0L44 13a3.38 3.38 0 0 1 0 5.62 8.34 8.34 0 0 0 0 13.78l2.67-4a3.53 3.53 0 0 1 0-5.78A8.19 8.19 0 0 0 46.64 9Zm12.21 0-2.67 4a3.38 3.38 0 0 1 0 5.62 8.34 8.34 0 0 0 0 13.78l2.67-4a3.53 3.53 0 0 1 0-5.78 8.19 8.19 0 0 0 0-13.62" />
        <path
          data-name="&lt;Path&gt;"
          class="shape-fill-primary"
          d="M45.19 76.56A26.64 26.64 0 0 1 18.58 50v-7.26H71.8V50a26.64 26.64 0 0 1-26.61 26.56" />
      </g>
    </ng-container>

    <ng-container *ngSwitchCase="'calender'">
      <path
        class="shape-fill-light-gray"
        d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20zM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2" />
    </ng-container>

    <ng-container *ngSwitchCase="'mobile'">
      <g data-name="&lt;Group&gt;">
        <path
          data-name="&lt;Path&gt;"
          class="shape-fill-primary"
          d="M31 14.4h39c1.66 0 3.36 2 3.36 3.66v1.83H27.68v-1.83c0-1.66 1.7-3.66 3.32-3.66m39 72.3a3 3 0 0 1-2.09.62H32.2c-1.51 0-3.07-.86-3.07-2.33v-9.04h41.74v9.13A2.32 2.32 0 0 1 70 86.7" />
        <g data-name="&lt;Group&gt;">
          <g data-name="&lt;Group&gt;">
            <path
              data-name="&lt;Compound Path&gt;"
              class="shape-fill-dark"
              d="M75.09 83.37v-66a8 8 0 0 0-8-8h-34.7a8 8 0 0 0-8 8v66a8 8 0 0 0 8 8h34.76A8 8 0 0 0 72.87 89a7.72 7.72 0 0 0 2.22-5.63M69 85.25a2.6 2.6 0 0 1-1.88.8H32.39a2.68 2.68 0 0 1-2.67-2.67v-66a2.68 2.68 0 0 1 2.67-2.67h34.67a2.68 2.68 0 0 1 2.67 2.67v66a2.46 2.46 0 0 1-.73 1.87" />
          </g>
          <g data-name="&lt;Group&gt;">
            <path
              data-name="&lt;Path&gt;"
              class="shape-fill-dark"
              d="M27.05 19.82h45.46v5.35H27.05z" />
          </g>
          <g data-name="&lt;Group&gt;">
            <path
              data-name="&lt;Path&gt;"
              class="shape-fill-dark"
              d="M27.05 71.43h45.46v5.35H27.05z" />
          </g>
          <g data-name="&lt;Group&gt;">
            <path
              data-name="&lt;Path&gt;"
              class="shape-fill-dark"
              d="M52 78.65h-4.45a2.67 2.67 0 1 0 0 5.35H52a2.67 2.67 0 1 0 0-5.35" />
          </g>
        </g>
      </g>
    </ng-container>
    <ng-container *ngSwitchCase="'playIcon'">
      <path
        class="vide-pl-icon"
        d="M51.3,109c-2.067-.279-4.146-0.483-6.2-0.849A53.511,53.511,0,0,1,13.578,90.422,52.646,52.646,0,0,1,.281,59.959,52.986,52.986,0,0,1,11.308,21.368,52.616,52.616,0,0,1,43.05,1.283c16.286-3.395,31.223-.064,44.486,9.981A52.937,52.937,0,0,1,107.765,43.15c0.5,2.311.7,4.689,1.038,7.035,0.055,0.377.131,0.753,0.2,1.13V57.7c-0.087.766-.176,1.532-0.261,2.3a53.83,53.83,0,0,1-13.69,30.84A53.464,53.464,0,0,1,63.883,108.15c-2.051.364-4.129,0.569-6.195,0.848H51.3Zm53.168-54.485a49.974,49.974,0,1,0-49.981,49.955A50.092,50.092,0,0,0,104.469,54.513Zm-63.6-.053c0-6.562.008-13.123-.008-19.684a2.647,2.647,0,0,1,1.175-2.658c1.092-.615,2.024-0.163,2.963.443q15.244,9.815,30.5,19.611c2.311,1.486,2.315,3.18.011,4.662q-15.3,9.832-30.6,19.66c-1.38.887-2.524,0.992-3.268,0.075a3.634,3.634,0,0,1-.747-2.105C40.846,67.8,40.868,61.129,40.868,54.46Zm4.555,16.316,25.31-16.27L45.424,38.241V70.776Z" />
    </ng-container>
  </ng-container>
</svg>
