import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private router: Router) {

  }

  redirectToPage(url: String) {
    // Use the navigate method to redirect to the specified route
    this.router.navigate([url]).then(() => {
      window.location.reload();
      // Scroll to the top after the navigation is complete
      this.scrollToTop();
    });
  }

  scrollToTop() {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  metaDataContent() {
    return `Indoor play zone, 
    play zone, 
    kids, 
    kids play area, 
    fun, 
    exciting, 
    largest play zone, 
    kochi, 
    kerala, 
    Kids, 
    toddlers, 
    trampoline,
    Climbing wall,
    Party Hall,
    Birthday Party,
    Fun with friends,
    toddler play area, 
    play, 
    gadget free play zone, 
    kids activities, 
    birthday parties, 
    parties, 
    events, 
    physical activities, 
    parent child bonding , 
    cafe,Kids play zone, 
    Indoor play area,
    Gadget-free play,
    Screen-free zone,
    Active play,
    Children's entertainment,
    Safe play environment,
    Creative play,
    Imaginative play,
    Physical activities for kids,
    Fun games for children,
    Playtime without electronics,
    Interactive play zone,
    Unplugged play,
    Play and learn,
    Social interaction for kids,
    Playful environment,
    Engaging activities for children,
    Play center for kids,
    Active learning through play`
  }

}
