import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'app-news-room-page',
  templateUrl: './news-room-page.component.html',
  styleUrls: ['./news-room-page.component.css']
})
export class NewsRoomPageComponent {

  newsRoomArticles: any;
  constructor(
    private webSocketService: WebSocketService,
    private sharedService: SharedService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.getNewsArticlesUrls();
    this.titleService.setTitle("The Kids Capitol News Room");
    this.meta.updateTag({
      name: "description",
      content: `News Room page of The Kids Capitol. 
      Discover the latest coverage of The Kids Capitol, Kerala's largest gadget-free indoor kids play zone, in various media outlets including Instagram, newspapers, TV, and more. Stay informed about our featured stories, events, and special highlights in the news.`,
    });
    this.meta.updateTag({
      name: "keywords",
      content:
        "news, media coverage, Instagram, newspapers, TV, press, featured stories, events, highlights, The Kids Capitol, Kerala, gadget-free indoor kids play zone, family-friendly, children's activities, entertainment, updates.",
    });
  }

  getNewsArticlesUrls() {
    let url = '../../../assets/json/news-articles.json';
    this.webSocketService.getObjects(url).subscribe(data => {
      this.newsRoomArticles = data;
    }, (error) => {
      console.log(error)
    });
  }

  redirectToTestimonials() {
    this.sharedService.redirectToPage('/testimonials');
  }
}
