import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.css']
})
export class SvgIconComponent {
  @Input() icon!: string;
  @Input() iconHeight: any;
  @Input() iconWidth: any;
  @Input() iconClass: any;
  @Input() iconViewBox: any;

  constructor() {
    this.iconHeight = this.iconHeight ? this.iconHeight : 70;
    this.iconWidth = this.iconWidth ? this.iconWidth : 70;
    this.iconClass = this.iconClass ? this.iconClass : '';
    this.iconViewBox = this.iconViewBox ? this.iconViewBox : '0 0 100 100';
  }

}
