<section class="bg-white">
  <div class="relative h-full w-full py-16 pl-10 pr-7 xl:px-0">
    <div class="m-auto font-poppins lg:mb-16 xl:max-w-7xl">
      <h1
        class="mb-8 animate-slideAndAppearFromTop-25 text-center text-3xl font-extrabold md:text-4xl lg:text-5xl">
        <span>Start Your Own Play Area</span>
      </h1>
      <div class="flex animate-reveal content-center justify-center">
        <ul class="list-disc font-medium text-gray-600">
          <li class="mb-4 leading-7">
            The Kids Capitol builds and operates 100% gadget free indoor play
            space where children get to experience hours of vigorous play
            completely free from the influence of electronics and mind numbing
            gadgets. Frequent and regular play sessions packed with physical
            activity and mental stimulation are crucial to the development of a
            healthy mind and strong body. The Kids Capitol is equipped with the
            latest indoor playground equipment, A party Hall and Café "The Food
            Capitol" for the children and family to explore and follows
            strictest safety standards to guarantee your children and you are
            having fun in a safe environment.
          </li>
          <li class="mb-4 leading-7">
            We welcome children aged 0 – 14yrs to explore and play freely
            amongst activities, workshops, sporting activities and coaching and
            equipment best suited to their age range at the same time develop
            important motor skills and encourage social, emotional, physical and
            cognitive developments.
          </li>
          <li class="mb-4 leading-7">
            Our full service entertainment venue is not only a kids indoor
            playground dedicated to your child’s age and abilities but also a
            space for children birthday parties, private functions, corporate
            parties, school day trips, play dates and a lot more. We have worked
            hard to build a household name which boasts the newest indoor play
            equipment, latest designs, best of sporting activities and trained
            staff to deliver an outstanding experience to all our customers. A
            café with lip-smacking food & beverages, a co-working space(subject
            to design) for the working parents all overlooking the children
            playing with foot tapping music awaits you for a magical experience.
          </li>
          <li class="mb-4 leading-7">
            The Kids Capitol is the perfect place to catch up with friends, make
            new ones, and have a day out with your entire family regardless of
            the age.
          </li>
        </ul>
      </div>
      <h1 class="pt-6 text-center text-2xl font-extrabold">
        <span>COME LET'S CREATE MAGIC AT THE KIDS CAPITOL</span>
      </h1>
    </div>
  </div>
</section>

<section class="bg-color-secondary">
  <div class="m-auto h-full px-7 py-16 lg:px-0">
    <div
      class="grid grid-cols-1 gap-2 overflow-hidden font-poppins lg:grid-cols-2">
      <div
        class="order-2 flex h-full animate-reveal flex-wrap content-center justify-center lg:order-1">
        <div class="mb-4 h-fit lg:w-4/5 lg:pl-12">
          <h1 class="mb-6 text-3xl font-extrabold md:text-4xl lg:text-5xl">
            Our Vision
          </h1>
          <p class="mb-6 text-xl font-medium text-color-alternate">
            To create a benchmark of kids play area which are gadget free.
          </p>
          <p class="mb-6 text-base font-normal leading-7 text-gray-600">
            The Kids Capitol builds and operates 100% gadget free indoor play
            space where children get to experience hours of vigorous play
            completely free from the influence of electronics and mind numbing
            gadgets.
          </p>
          <div class="w-full pt-4 sm:w-3/4 md:w-full xl:w-3/4">
            <button
              (click)="downloadBrochure()"
              class="bg-color-white w-full transform rounded-full border-2 border-gray-900 p-4 font-bold text-gray-800 transition-all duration-500 ease-in-out hover:translate-y-[-4px] hover:shadow-xl md:w-1/2 lg:w-full"
              style="border-top-left-radius: 0%">
              Download Franchise Brochure
            </button>
          </div>
        </div>
      </div>
      <div
        class="relative order-1 mb-5 h-full w-full animate-reveal px-0 py-0 lg:order-2 lg:mb-0 lg:px-8 lg:py-8">
        <img
          class="rounded-lg"
          src="../../../assets/images/franchise/franchise-vision.jpg"
          alt=""
          width="100%"
          height="100%" />
        <img
          class="animate-float absolute right-1/3 top-4 h-16 w-16 sm:h-28 sm:w-28 md:h-fit md:w-fit"
          src="../../../assets/images/franchise/franchise-gadget-free.png"
          alt="" />
      </div>
    </div>
  </div>
</section>

<section class="bg-white">
  <div class="relative h-full w-full py-16 pl-10 pr-7 xl:px-0">
    <div class="m-auto mb-16 font-poppins xl:max-w-7xl">
      <h1
        class="mb-8 animate-slideAndAppearFromTop-25 text-center text-3xl font-extrabold md:text-4xl lg:text-5xl">
        <span>Why Us</span>
      </h1>
      <div class="flex animate-reveal content-center justify-center">
        <ul class="list-disc font-normal text-gray-600">
          <li class="mb-4 leading-7">
            The Kids Capitol partners are supported by a team of professional
            and talented people who are constantly refining and improving what
            is already a tried &amp; tested formula.
          </li>
          <li class="mb-4 leading-7">
            All our partners, be it a franchisee/a brand licensee or an
            independent brand, have the opportunity to generate revenue via
            multiple channels, including, Play Area Entry | Magical Birthday
            Party Packages | Party Planning (Outsource And In-house) Full
            Service Cafe (Outsource &amp; In-house) | After Hours Functions |
            Group And School Bookings | Branded Merchandise Sales Casual Drop
            And Shop (Subject To Licensing) | Website And Email Marketing.
          </li>
          <li class="mb-4 leading-7">
            Loyalty Programme, Memberships, Multiple Entry Tickets | Branded
            Themed Rides (Optional) Before And/Or After School Care (Subject To
            Licensing) | Vacation/school Holiday Care (Subject To Licensing)
            Outdoor Play Area (As Per Space Availability) | Outsourced Vendors.
          </li>
          <li class="mb-4 leading-7">
            The split between the percentages of sales from each revenue channel
            depends on the size and demography of the play centre. Our carefully
            selected locations, pricing, operational policies and procedures
            take the hard work out of setting up a successful business.
          </li>
          <li class="mb-4 leading-7">
            Continuous improvement is a key component to the success of any
            business and all our procedures, operational policies and methods
            are monitored and updated regularly to support the needs of our
            partners and customers.
          </li>
          <li class="leading-7">
            We assist with complete selection, design, installation &amp;
            operation ( if required ) which is managed by a team of specialist
            installers who are qualified by our equipment manufacturers and all
            aspects of the design and layout of your centre are proven to
            maximise your income potential and deliver a fantastic playground
            experience for your customers.
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="bg-color-secondary">
  <div class="m-auto h-full px-6 py-16 lg:px-0">
    <div
      class="grid grid-cols-1 gap-2 overflow-hidden font-poppins lg:grid-cols-2">
      <div class="relative h-full w-full animate-reveal px-0 py-8 lg:px-8">
        <img
          class="rounded-lg"
          src="../../../assets/images/franchise/fraanchise-we-do-it-all.png"
          alt=""
          width="100%"
          height="100%" />
      </div>
      <div
        class="flex h-full animate-reveal flex-wrap content-center justify-center">
        <div class="mb-4 h-fit lg:w-4/5 lg:pl-12">
          <h1 class="mb-6 text-3xl font-extrabold md:text-4xl lg:text-5xl">
            We Do It All
          </h1>
          <p class="mb-6 text-xl font-medium text-gray-800">
            DESIGN, INSTALL, CREATE BUILDING PLAY ZONES WHERE FUN & FITNESS ARE
            UNLIMITED
          </p>
          <p class="mb-6 text-base font-normal leading-7 text-gray-600">
            We don’t deliver a solution, we deliver an experience. One that is
            unforgettable, one that will continue to surprise your audience for
            the longest time. Each play area is custom built to deliver a
            distinctive character.
          </p>
          <div class="w-full pt-4 sm:w-3/4 lg:w-full xl:w-3/4">
            <button
              (click)="downloadBrochure()"
              class="bg-color-white w-full transform rounded-full border-2 border-gray-900 p-4 font-bold text-gray-800 transition-all duration-500 ease-in-out hover:translate-y-[-4px] hover:shadow-xl md:w-1/2 lg:w-full"
              style="border-top-left-radius: 0%">
              Download Franchise Brochure
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-white">
  <div class="relative h-full w-full px-7 py-16 xl:px-0">
    <div class="m-auto mb-16 overflow-hidden font-poppins xl:max-w-7xl">
      <h1
        class="mb-8 animate-slideAndAppearFromTop-25 text-center text-3xl font-extrabold md:text-4xl lg:text-5xl">
        <span>Invest With Us</span>
      </h1>
      <div class="flex animate-reveal flex-col content-center justify-center">
        <h1
          class="text-gray-870 mb-3 text-2xl font-extrabold text-color-pink md:text-3xl">
          INVEST WITH US !
        </h1>
        <p class="mb-5 text-base font-medium leading-7 text-gray-700">
          The initial investment required for setting up can vary depending on
          whether you choose to design and build a new play centre, purchase an
          existing centre in operation or acquire a competitor’s store. To
          design and build a small (3500 Sq. Ft.) to medium (5000 Sq. Ft. )
          party and play-town will cost about ₹ 50 lakhs – ₹ 70 lakhs and a
          large store (8000 Sq. Ft. And above) will cost about 85 lakhs to 1
          crore. The average fit-out rate is about ₹ 1900/- per sq. ft depending
          on the theme selection.
        </p>
        <h1
          class="text-gray-870 mb-3 text-2xl font-extrabold text-color-pink md:text-3xl">
          YOUR INVESTMENT INCLUDES
        </h1>
        <p class="mb-5 text-base font-medium leading-7 text-gray-700">
          Site Finding & Lease Negotiations | Cad Designs , Electrical And Civil
          Layouts | Interior / Exterior Design For Store Fitouts Turnkey Project
          Management | Shipping And Logistic Support | Playground Design, Fitout
          And Installation Initial Induction, Training And Opening Support |
          Branded Merchandise | Signage & Branded Marketing Materials For
          Opening Ongoing Operational Support | Ongoing Marketing And
          Promotional Support.
        </p>
        <h1
          class="text-gray-870 mb-3 text-2xl font-extrabold text-color-pink md:text-3xl">
          NOT INCLUDED
        </h1>
        <p class="mb-5 text-base font-medium leading-7 text-gray-700">
          Advance Rentals For Property Lease | Civil Work To Be Done On The
          Property Before Lease. Main Sign Board | Electrical fittings.
        </p>
        <h1
          class="text-gray-870 mb-3 text-2xl font-extrabold text-color-pink md:text-3xl">
          APPROXIMATE REVENUE SPLIT
        </h1>
        <p class="mb-5 text-base font-medium leading-7 text-gray-700">
          <strong>30%</strong>
          Play Area Entry |
          <strong>25%</strong>
          Birthday Party Bookings |
          <strong>20%</strong>
          Cafe (If In-house) And
          <strong>10-15%</strong>
          (Outsourced)
          <strong>20%</strong>
          School Day Trips, After Hour Functions, Other Sales |
          <strong>5%</strong>
          Merchandising &amp;Misc.
          <br />
          Kids Capitol Party &amp; Play is a great business for cashflow and the
          average ROI on this business is between
          <strong>16-18</strong>
          months.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="bg-color-pink text-white">
  <div class="m-auto h-full px-7 pb-16 lg:px-0 lg:py-16">
    <div class="grid grid-cols-1 gap-2 font-poppins lg:grid-cols-2">
      <div
        class="relative mb-5 h-full w-full animate-reveal lg:mb-0 lg:py-8 lg:pr-8">
        <img
          class="rounded-lg"
          src="../../../assets/images/franchise/franchise-requirements.png"
          alt=""
          width="100%"
          height="100%" />
      </div>
      <div
        class="flex h-full animate-reveal flex-wrap content-center lg:justify-center">
        <div class="mb-4 h-fit pl-4 lg:w-4/5 lg:pl-0">
          <h1 class="mb-12 text-3xl font-extrabold md:text-4xl lg:text-5xl">
            Requirements
          </h1>
          <div
            class="mb-12 flex animate-reveal content-center justify-center pl-4 lg:pl-4">
            <ul class="list-disc font-medium text-white">
              <li class="mb-2 leading-5">
                Space Required: -Min 5000 – 7000 Sqft carpet with minimum
                pillars.
              </li>
              <li class="mb-2 leading-5">
                Investment: – Approx. 1900 per Sqft (Excluding Shed/Civil)
              </li>
              <li class="mb-2 leading-5">Franchise Fee: – 10lacs + GST</li>
              <li class="mb-2 leading-5">ROI: – 16-18months</li>
              <li class="mb-2 leading-5">Agreement Term: – 9years</li>
              <li class="mb-2 leading-5">MinHeight: – 15-18ft clear</li>
            </ul>
          </div>
          <div class="w-full pt-4 sm:w-3/4 lg:w-full xl:w-3/4">
            <button
              (click)="downloadBrochure()"
              class="w-full transform rounded-full bg-color-primary p-4 font-bold text-white transition-all duration-500 ease-in-out hover:translate-y-[-4px] lg:w-full"
              style="border-top-left-radius: 0%">
              Download Franchise Brochure
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
