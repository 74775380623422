import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { SharedService } from "src/app/services/shared.service";
import { WebSocketService } from "src/app/services/web-socket.service";

@Component({
  selector: "app-privacy-policy-page",
  templateUrl: "./privacy-policy-page.component.html",
  styleUrls: ["./privacy-policy-page.component.css"],
})
export class PrivacyPolicyPageComponent {
  privacyPolicyTerms: any;

  constructor(
    private webSocketService: WebSocketService,
    private sharedService: SharedService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.getPrivacyPolicyTerms();
    this.titleService.setTitle("The Kids Capitol Privacy Policy");
    this.meta.updateTag({
      name: "description",
      content: "Privacy policy page of The Kids Capitol.",
    });
    this.meta.updateTag({
      name: "keywords",
      content: "Privacy policy, Children's privacy, Data protection, Personal information protection, Privacy notice, Privacy statement, Privacy regulations, Online safety for kids, Parental consent",
    });
  }

  getPrivacyPolicyTerms() {
    let url = "../../../assets/json/privacy-policy.json";
    this.webSocketService.getObjects(url).subscribe(
      (data) => {
        this.privacyPolicyTerms = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
