<section class="animate-reveal bg-color-secondary">
  <div class="m-auto h-full max-w-7xl px-7 py-16 lg:py-32 xl:px-0">
    <div class="grid grid-cols-1 gap-0 font-poppins lg:grid-cols-3">
      <div
        class="mb-4 flex flex-col justify-center pr-12 lg:col-span-2 lg:mb-0">
        <h1 class="mb-10 text-3xl font-extrabold lg:text-4xl">
          <span class="mb-5 block text-color-alternate">
            Kerala's First & Largest
          </span>
          100% Gadget Free Indoor Play & Party Zone
        </h1>

        <p class="mb-8 font-normal leading-8 text-gray-700">
          <span class="mb-4 block">
            The Kids Capitol is the perfect place to catch up with friends, make
            new ones, and have a day out with your entire family regardless of
            the age.
          </span>
          <span class="block">
            Frequent and regular play sessions packed with physical activity and
            mental stimulation are crucial to the development of a healthy mind
            and strong body. The Kids Capitol is equipped with the latest indoor
            playground equipment.
          </span>
        </p>
        <div class="pb-8 text-start lg:pb-0">
          <button
            (click)="redirectToUrl('/contact')"
            class="transform rounded-full bg-color-primary px-10 py-4 font-bold text-white transition-all duration-500 ease-in-out hover:translate-y-[-4px]"
            style="border-top-left-radius: 0%">
            Book Now
          </button>
        </div>
      </div>
      <div>
        <div class="rounded-3xl bg-white px-8 py-12">
          <h3 class="mb-8 text-2xl font-bold">Something for everyone</h3>
          <p class="mb-6 flex flex-row flex-nowrap font-normal text-gray-700">
            <span class="mr-2 block pt-1 text-color-alternate">
              <i class="fas fa-check-circle"></i>
            </span>
            <span>
              The Kids Capitol is a place for unbelievable adventures.
            </span>
          </p>
          <p
            class="mb-6 flex flex-row flex-nowrap pt-1 font-normal text-gray-700">
            <span class="mr-2 block text-color-alternate">
              <i class="fas fa-check-circle"></i>
            </span>
            <span>
              Climbing, jumping, sliding, crawling and most importantly having
              fun.
            </span>
          </p>
          <p
            class="mb-6 flex flex-row flex-nowrap pt-1 font-normal text-gray-700">
            <span class="mr-2 block text-color-alternate">
              <i class="fas fa-check-circle"></i>
            </span>
            <span>
              Challenge yourself, test your limits at The Kids Capitol indoor
              playground.
            </span>
          </p>
          <p
            class="mb-6 flex flex-row flex-nowrap pt-1 font-normal text-gray-700">
            <span class="mr-2 block text-color-alternate">
              <i class="fas fa-check-circle"></i>
            </span>
            <span>A café with lip-smacking food & beverages.</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-color-secondary font-poppins">
  <app-play-area-activities></app-play-area-activities>
</section>

<section class="bg-color-green pt-20">
  <div class="m-auto h-full max-w-7xl px-7 pb-16 lg:pb-32 xl:px-0">
    <div class="grid grid-cols-1 gap-0 font-poppins lg:grid-cols-2">
      <div class="h-full w-full animate-reveal pb-4 lg:pb-0">
        <img
          class="h-full w-full rounded-3xl"
          src="../../../assets/images/tkc/team.jpeg"
          alt="" />
      </div>
      <div class="flex animate-reveal flex-col justify-center pl-0 lg:pl-12">
        <p class="mb-6 font-semibold text-color-primary">
          THE KIDS CAPITOL - PLAY & PARTY ZONE
        </p>
        <h1 class="mb-10 text-3xl font-extrabold text-gray-900 lg:text-6xl">
          Our team will gladly assist you
        </h1>
        <p class="mb-8 font-normal leading-7 text-gray-700">
          <span class="mb-4 block">
            A party hall and Café for the children and family to explore and
            follows strictest safety standards to guarantee your children and
            you are having fun in a safe environment.
          </span>
          <span class="block">
            We welcome children aged 1 yr – 14 yrs to explore and play freely
            amongst activities & workshops. These activities help them develop
            motor skills and encourage social, emotional, physical and cognitive
            developments.
          </span>
        </p>
        <div class="flex flex-row justify-start pl-3">
          <div class="-rotate-45 transform pr-2">
            <span
              class="pl-3 text-3xl text-color-alternate sm:pl-0 lg:text-4xl">
              <i class="fas fa-thumbtack"></i>
            </span>
          </div>
          <p class="text-2xl font-semibold text-gray-900">
            Come Lets Create Magic at The Kids Capitol....
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
