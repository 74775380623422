import { Component, Input } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'app-testimonials-page',
  templateUrl: './testimonials-page.component.html',
  styleUrls: ['./testimonials-page.component.css']
})
export class TestimonialsPageComponent {

  @Input() isGalleryPage: any;

  testimonialsImages: any;

  constructor(private sharedService: SharedService, private webSocketService: WebSocketService, private titleService: Title, private meta: Meta) {
    this.getTestimonialsImageUrls();
    this.titleService.setTitle("The Kids Capitol Testimonials");
    this.meta.updateTag({
      name: "description",
      content: `Testimonials page of The Kids Capitol. 
      Discover firsthand accounts from families who have experienced the joy and excitement of The Kids Capitol, Kerala's gadget-free indoor kids play zone. Read testimonials highlighting the fun, safety, and entertainment provided in our dedicated kids play area.`,
    });
    this.meta.updateTag({
      name: "keywords",
      content:
        "testimonials, reviews, feedback, customer experiences, family-friendly, kids play area, indoor play zone, gadget-free, children's activities, parent testimonials, customer satisfaction, fun environment, safe play area, entertainment, Kerala, child-friendly, positive feedback.",
    });
  }

  getTestimonialsImageUrls() {
    let url = '../../../assets/json/testimonials-images.json';
    this.webSocketService.getObjects(url).subscribe(data => {
      this.testimonialsImages = data;
      if (this.isGalleryPage) {
        this.testimonialsImages = this.testimonialsImages.slice(0, 3);
      }
    }, (error) => {
      console.log(error);
    });
  }

  redirectToGallery(url: any) {
    this.sharedService.redirectToPage(url);
  }
}
