import { Component } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'app-terms-and-conditions-page',
  templateUrl: './terms-and-conditions-page.component.html',
  styleUrls: ['./terms-and-conditions-page.component.css']
})
export class TermsAndConditionsPageComponent {

  termsAndConditions: any;

  constructor(private sharedService: SharedService, private webSocketService: WebSocketService) {
    this.getTestimonialsImageUrls();
  }


  getTestimonialsImageUrls() {
    let url = '../../../assets/json/terms-and-conditions.json';
    this.webSocketService.getObjects(url).subscribe(data => {
      this.termsAndConditions = data;
    }, (error) => {
      console.log(error);
    });
  }
}
