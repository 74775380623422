import { Component } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-play-area-activities',
  templateUrl: './play-area-activities.component.html',
  styleUrls: ['./play-area-activities.component.css']
})
export class PlayAreaActivitiesComponent {

  constructor(
    private sharedService: SharedService
  ) {

  }

  redirectToUrl(url: any) {
    this.sharedService.redirectToPage(url);
  }
}
