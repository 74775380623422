import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { SharedService } from "src/app/services/shared.service";
import { WebSocketService } from "src/app/services/web-socket.service";

@Component({
  selector: "app-about-page",
  templateUrl: "./about-page.component.html",
  styleUrls: ["./about-page.component.css"],
})
export class AboutPageComponent {
  teamDetails: any;
  fAQLeftDetails: any;
  fAQRightDetails: any;

  constructor(
    private sharedService: SharedService,
    private webSocketService: WebSocketService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.getTeamMembersDetails();
    this.titleService.setTitle("About - The Kids Capitol");
    this.meta.updateTag({
      name: "description",
      content: "About - The Kids Capitol",
    });
    this.meta.updateTag({
      name: "keywords",
      content: this.sharedService.metaDataContent(),
    });

    this.meta.updateTag({
      property: "og:title",
      content: "About - The Kids Capitol",
    });
    this.meta.updateTag({
      property: "og:description",
      content: "About - The Kids Capitol",
    });

    this.meta.updateTag({
      property: "twitter:title",
      content: "About - The Kids Capitol",
    });
    this.meta.updateTag({
      property: "twitter:description",
      content: "About - The Kids Capitol",
    });
  }

  getTeamMembersDetails() {
    let url = "../../../assets/json/team-details.json";
    this.webSocketService.getObjects(url).subscribe(
      (data) => {
        data.map((val: any) => {
          if (val.teamDetails) {
            this.teamDetails = val.teamDetails;
          }

          if (val.frequentlyAskedQuestions) {
            let fAQDetailsTotalLength = val.frequentlyAskedQuestions.length;
            let fAQDetailsLeftLength = Math.trunc(fAQDetailsTotalLength / 2);
            this.fAQLeftDetails = val.frequentlyAskedQuestions.slice(
              0,
              fAQDetailsLeftLength
            );
            this.fAQRightDetails = val.frequentlyAskedQuestions.slice(
              fAQDetailsLeftLength,
              fAQDetailsTotalLength
            );
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  redirectToUrl(url: any) {
    this.sharedService.redirectToPage(url);
  }

  showQuestion(question: any) {
    if (question.showAnswer) {
      question.showAnswer = !question.showAnswer;
    } else {
      this.fAQLeftDetails.map((val: any) => {
        if (val == question) {
          val.showAnswer = true;
        } else {
          val.showAnswer = false;
        }
      });

      this.fAQRightDetails.map((val: any) => {
        if (val == question) {
          val.showAnswer = true;
        } else {
          val.showAnswer = false;
        }
      });
    }
  }
}
