<section class="bg-color-secondary">
  <div class="m-auto h-full px-7 py-16 md:py-32 xl:px-0">
    <div class="grid grid-cols-1 gap-2 font-poppins lg:grid-cols-2">
      <div
        class="mb-5 flex h-full w-full animate-reveal flex-wrap justify-start lg:mb-0 lg:justify-end lg:p-10">
        <div class="xl:max-w-xl">
          <h1
            class="mb-6 text-3xl font-extrabold leading-normal md:text-4xl lg:text-5xl">
            <span class="text-color-alternate">Have you got</span>
            <br />
            <span>a question for us?</span>
          </h1>
          <p class="font-normal text-gray-600">
            Questions or special inquiries? Fill out a form and we’ll be in
            touch as soon as possible.
          </p>
        </div>
      </div>
      <div class="max-w-xl animate-reveal rounded-3xl bg-white p-10">
        <h1 class="mb-6 text-3xl font-extrabold">Get in touch</h1>
        <app-get-in-touch></app-get-in-touch>
      </div>
    </div>
  </div>
</section>

<section class="bg-color-secondary font-poppins">
  <div class="m-auto h-full px-7 pb-16 lg:px-0 lg:pb-32">
    <div
      class="grid animate-reveal grid-cols-1 gap-0 font-poppins lg:grid-cols-2">
      <div
        class="mb-5 flex flex-wrap content-center justify-end lg:mb-0 lg:p-8">
        <div class="w-full xl:max-w-xl">
          <iframe
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15718.891156886713!2d76.3546621!3d9.9570065!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b08731b8bf63fe1%3A0xead9027a8542e977!2sThe%20Kids%20Capitol!5e0!3m2!1sen!2sin!4v1690546850860!5m2!1sen!2sin"
            class="relative h-96 w-full"
            allowfullscreen=""
            loading="lazy"></iframe>
        </div>
      </div>
      <div class="lg:pl-14">
        <h1
          class="mb-10 text-3xl font-extrabold md:text-4xl lg:text-5xl xl:text-6xl">
          Find Us Here
        </h1>
        <div class="mb-12 flex flex-col">
          <div class="flex flex-nowrap sm:flex-wrap">
            <div class="pr-5">
              <app-svg-icon
                [icon]="'location'"
                [iconHeight]="60"
                [iconWidth]="60"></app-svg-icon>
            </div>
            <div
              class="flex flex-col justify-center border-l border-black pl-5">
              <p class="mb-3 font-semibold text-color-alternate">LOCATION</p>
              <p
                class="text-sm font-medium leading-6 text-gray-500 sm:text-base">
                <span>1st floor, Carnival High Street,</span>
                <br />
                <span>National Highway 49</span>
                <br />
                <span>, Irumpanam, Thrippunithura,</span>
                <br />
                <span>Kochi, Ernakulam, Kerala 682301</span>
              </p>
            </div>
          </div>
          <div class="flex flex-nowrap sm:flex-wrap">
            <div class="pr-5 pt-5">
              <app-svg-icon
                [icon]="'alarm'"
                [iconHeight]="60"
                [iconWidth]="60"></app-svg-icon>
            </div>
            <div
              class="flex flex-col justify-center border-l border-black pl-5">
              <p class="mb-3 pt-4 font-semibold text-color-alternate">HOURS</p>
              <p
                class="text-sm font-medium leading-6 text-gray-500 sm:text-base">
                <span>Mon- Thu: 1:00 pm-9:00 pm</span>
                <br />
                <span>Fri- Sun: 11:00 am-10:00 pm</span>
                <br />
                <span>Holidays: 11:00 am-10:00 pm</span>
              </p>
            </div>
          </div>
          <div class="flex flex-nowrap sm:flex-wrap">
            <div class="pr-5 pt-5">
              <app-svg-icon
                [icon]="'mobile'"
                [iconHeight]="60"
                [iconWidth]="60"></app-svg-icon>
            </div>
            <div
              class="flex flex-col justify-center border-l border-black pl-5">
              <p class="mb-3 pt-4 font-medium text-color-alternate">CONTACT</p>
              <p
                class="text-sm font-normal leading-6 text-gray-500 sm:text-base">
                <span>+91 7736572227</span>
                <br />
                <span>thekidscapitol@gmail.com</span>
              </p>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="flex flex-nowrap content-center">
            <a
              class="mr-5 transform transition-all duration-500 ease-in-out hover:translate-y-[-4px]"
              href="//www.instagram.com/thekidscapitol"
              target="_blank"
              title="Visit our Instagram account">
              <img
                height="40"
                width="40"
                src="../../../assets/images/instagram.png"
                alt="Instagram logo" />
            </a>

            <a
              class="mr-5 transform transition-all duration-500 ease-in-out hover:translate-y-[-4px]"
              href="//www.facebook.com/thekidscapitol"
              target="_blank"
              title="Visit our Facebook account">
              <img
                height="40"
                width="40"
                src="../../../assets/images/facebook.png"
                alt="Facebook logo" />
            </a>
            <a
              class="transform transition-all duration-500 ease-in-out hover:translate-y-[-4px]"
              href="//www.youtube.com/@ThekidsCapitol"
              target="_blank"
              title="Visit our Youtube account">
              <img
                height="41"
                width="41"
                src="../../../assets/images/YouTube.png"
                alt="Youtube logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-color-yellow">
  <div class="m-auto h-full px-7 lg:px-0">
    <div class="grid grid-cols-1 gap-2 font-poppins lg:grid-cols-2">
      <div
        class="order-2 flex h-full animate-reveal flex-wrap content-center justify-center lg:order-1">
        <div class="mb-4 h-fit lg:w-4/5 lg:pl-12">
          <p class="mb-4 font-semibold text-color-primary md:mb-8">
            CHECK OUT OUR
          </p>
          <h1 class="mb-6 text-3xl font-extrabold lg:text-5xl">
            Opening Hours
          </h1>
          <div>
            <h3
              class="flex flex-nowrap text-xl font-bold text-color-alternate sm:text-2xl">
              <span class="block w-36 pb-3">Mon - Thu</span>
              <span class="block border-l border-black pl-6">1 PM - 9 PM</span>
            </h3>
            <h3 class="flex flex-nowrap text-xl font-bold sm:text-2xl">
              <span class="block w-36 pb-3">Fri - Sun</span>
              <span class="block border-l border-black pl-6">
                11 AM - 10 PM
              </span>
            </h3>
            <h3 class="flex flex-nowrap text-xl font-bold sm:text-2xl">
              <span class="block w-36 pb-3">Holidays</span>
              <span class="block border-l border-black pl-6">
                11 AM - 10 PM
              </span>
            </h3>
          </div>
        </div>
      </div>
      <div
        class="order-1 h-full w-full animate-reveal py-8 pr-0 lg:order-2 lg:pl-8">
        <img
          class=""
          src="../../../assets/images/tkc/daivik.png"
          alt=""
          width="100%"
          height="100%" />
      </div>
    </div>
  </div>
</section>
