import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { SharedService } from "src/app/services/shared.service";

@Component({
  selector: "app-contact-page",
  templateUrl: "./contact-page.component.html",
  styleUrls: ["./contact-page.component.css"],
})
export class ContactPageComponent {
  constructor(
    private meta: Meta,
    private sharedService: SharedService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Contact - The Kids Capitol");
    this.meta.updateTag({
      name: "description",
      content: `Contact The Kids Capitol today for inquiries, customer support, and general information. Fill out our contact form or reach out to us via phone or email.
        Get in touch with The Kids Capitol through our contact page. We're here to assist you with any questions or concerns. Contact us now!.
        Looking to contact The Kids Capitol? Find our contact information and reach out to us for inquiries, support, or partnership opportunities.`,
    });
    this.meta.updateTag({
      name: "keywords",
      content:
        "Contact, Contact us, Get in touch, Reach out, Contact information, Contact details, Contact form, Contact page, Customer support, Inquiry",
    });

    this.meta.updateTag({
      property: "og:title",
      content: "Contact - The Kids Capitol",
    });
    this.meta.updateTag({
      property: "og:description",
      content: `Contact The kids capitol today for inquiries, customer support, and general information. Fill out our contact form or reach out to us via phone or email.
        Get in touch with The Kids Capitol through our contact page. We're here to assist you with any questions or concerns. Contact us now!.
        Looking to contact The Kids Capitol? Find our contact information and reach out to us for inquiries, support, or partnership opportunities.`,
    });

    this.meta.updateTag({
      property: "twitter:title",
      content: "Contact - The Kids Capitol",
    });
    this.meta.updateTag({
      property: "twitter:description",
      content: `Contact The kids capitol today for inquiries, customer support, and general information. Fill out our contact form or reach out to us via phone or email.
        Get in touch with The Kids Capitol through our contact page. We're here to assist you with any questions or concerns. Contact us now!.
        Looking to contact The Kids Capitol? Find our contact information and reach out to us for inquiries, support, or partnership opportunities.`,
    });
  }
}
