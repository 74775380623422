import { Component, HostListener, ViewChild, ElementRef } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { SharedService } from "src/app/services/shared.service";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"],
})
export class HomePageComponent {
  @ViewChild("homePageVideo") homePageVideo!: ElementRef<HTMLVideoElement>;
  // // Flag to track whether the element should be visible
  // isElementVisible = false;
  // isPartyElementOneVisible = false;
  // isPartyElementTwoVisible = false;
  // isPartyElementThreeVisible = false;

  // // Threshold value to determine when the element should become visible
  // scrollThreshold = 400; // Adjust this value as needed

  // // Listen for the window scroll event
  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   const scrollPo0sition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
  //   // Check if the scroll position is beyond the threshold
  //   console.log(scrollPosition, this.scrollThreshold);
  //   this.isElementVisible = scrollPosition > this.scrollThreshold;
  // }

  // // @HostListener('window:scroll', ['$event'])
  // // onWindowScroll(event: Event): void {
  // //   // Handle window scroll event here
  // //   console.log('Window scrolled!', event);
  // // }
  isVideoVisible = false;

  constructor(
    private sharedService: SharedService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle(
      "The Kids Capitol - Kochi's First & Largest Gadget Free Kids Indoor Play and Party Zone"
    );
    this.meta.updateTag({
      name: "description",
      content:
        "Welcoming your little kids, immerse in hours of play, unleash their imagination at Kerala's First, Largest and Ultimate Gadget-free Indoor Play and Party Zone.",
    });

    this.meta.updateTag({
      property: "og:title",
      content:
        "The Kids Capitol - Kochi's First & Largest Gadget Free Kids Indoor Play and Party Zone.",
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Welcoming your little kids, immerse in hours of play, unleash their imagination at Kerala's First, Largest and Ultimate Gadget-free Indoor Play and Party Zone.",
    });

    this.meta.updateTag({
      property: "twitter:title",
      content:
        "The Kids Capitol - Kochi's First & Largest Gadget Free Kids Indoor Play and Party Zone.",
    });
    this.meta.updateTag({
      property: "twitter:description",
      content:
        "Welcoming your little kids, immerse in hours of play, unleash their imagination at Kerala's First, Largest and Ultimate Gadget-free Indoor Play and Party Zone.",
    });
    this.meta.updateTag({
      name: "keywords",
      content: this.sharedService.metaDataContent(),
    });
  }

  ngAfterViewInit(): void {
    if (this.homePageVideo && this.homePageVideo.nativeElement.paused) {
      this.homePageVideo.nativeElement.muted = true;
      this.homePageVideo.nativeElement.play();
    }
  }

  redirectToUrl(url: any) {
    this.sharedService.redirectToPage(url);
  }

  openVideo() {
    this.isVideoVisible = true;
  }

  closeVideo() {
    this.isVideoVisible = false;
  }
}
