<section class="animate-reveal md:h-screen">
  <div class="h-80 w-full md:h-full">
    <div class="relative h-80 w-full md:h-full">
      <div class="relative h-80 overflow-hidden rounded-lg md:h-full">
        <div class="h-80 duration-700 ease-in-out md:h-full">
          <img
            src="../../../assets/images/tkc/tkc-mobile.jpeg"
            class="absolute left-1/2 top-1/2 block h-80 w-full -translate-x-1/2 -translate-y-1/2 sm:hidden md:h-full"
            alt="..." />
          <img
            src="../../../assets/images/tkc/tkc.webp"
            class="absolute left-1/2 top-1/2 hidden h-80 w-full -translate-x-1/2 -translate-y-1/2 sm:block md:h-full"
            alt="..." />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- About us section -->
<section class="bg-color-secondary font-poppins">
  <div class="m-auto h-full px-7 py-16 lg:px-0 lg:py-32">
    <div class="grid grid-cols-1 gap-0 font-poppins lg:grid-cols-2">
      <div class="order-2 animate-reveal lg:order-1 lg:pl-32">
        <p class="text-lg font-semibold text-color-primary">ABOUT US</p>
        <!-- <h1 class="mb-6 text-5xl font-extrabold">100% Gadget Free</h1> -->
        <h1 class="text2xl my-6 font-bold">
          Kerala's First & Largest 100% Gadget Free Indoor Play & Party Zone
        </h1>
        <span class="mb-6 block text-sm leading-6 text-gray-500">
          Imagine a play space where your kids are not amazed by the screens and
          digital illusions, but stay amused for hours with all sweat and play &
          fun and frolic! The Kids Capitol is that space for your wonderful kids
          to come unplug, imagine, play and have unlimited fun! With lots of
          innovative, thoughtful and physically fun filled activities, let your
          little ones explore the world of play and learning together here.

          <p>
            Add spark to their birthdays and beat their hunger pangs with
            yummilicious food with us at The Kids Capitol.
          </p>

          <p>
            This wonderful space is inspired by a mother herself, so you can be
            assured of the child safety and covid safety norms that will be
            followed to keep your little ones safe.
          </p>
        </span>
        <div class="mb-12 flex flex-col">
          <div class="flex flex-nowrap">
            <div class="pr-5">
              <app-svg-icon
                [icon]="'location'"
                [iconHeight]="50"
                [iconWidth]="50"></app-svg-icon>
            </div>
            <div
              class="flex flex-col justify-center border-l border-black pl-5">
              <p class="mb-3 font-medium text-color-alternate">LOCATION</p>
              <p class="text-sm font-normal leading-6 text-gray-500">
                <span>1st floor, Carnival High Street,</span>
                <br />
                <span>National Highway 49</span>
                <br />
                <span>, Irumpanam, Thrippunithura,</span>
                <br />
                <span>Kochi, Ernakulam, Kerala 682301</span>
              </p>
            </div>
          </div>
          <div class="flex flex-nowrap">
            <div class="pr-5 pt-5">
              <app-svg-icon
                [icon]="'alarm'"
                [iconHeight]="50"
                [iconWidth]="50"></app-svg-icon>
            </div>
            <div
              class="flex flex-col justify-center border-l border-black pl-5">
              <p class="mb-3 pt-4 font-medium text-color-alternate">HOURS</p>
              <p class="text-sm font-normal leading-6 text-gray-500">
                <span>Mon- Thu: 1:00 pm-9:00 pm</span>
                <br />
                <span>Fri- Sun: 11:00 am-10:00 pm</span>
                <br />
                <span>Holidays: 11:00 am-10:00 pm</span>
              </p>
            </div>
          </div>
        </div>
        <div class="w-40">
          <button
            (click)="redirectToUrl('/contact')"
            class="w-full transform rounded-full bg-color-primary p-4 font-bold text-white transition-all duration-500 ease-in-out hover:translate-y-[-4px]"
            style="border-top-left-radius: 0%">
            Book Now
          </button>
        </div>
      </div>

      <div
        class="relative order-1 mb-4 h-full w-full animate-reveal p-0 lg:order-2 lg:mb-0 lg:p-8">
        <img
          class="rounded-3xl"
          src="../../../assets/images/tkc/contact.webp"
          alt=""
          width="100%"
          height="100%" />
        <div
          class="play-icon absolute inset-0 bottom-3/4 left-3/4 flex items-center justify-center lg:bottom-0 lg:left-1/2">
          <div
            *ngIf="isVideoVisible"
            id="video-container"
            class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
            <div
              class="flex h-1/2 w-11/12 items-center justify-center sm:h-4/5 sm:w-4/5">
              <iframe
                id="youtube-video"
                class="h-full w-full"
                src="https://www.youtube.com/embed/CnKZDq-nels"
                frameborder="0"
                allowfullscreen></iframe>
              <button
                id="close-video"
                title="Play Video"
                class="absolute right-4 top-4 text-white focus:outline-none"
                (click)="closeVideo()">
                <svg
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <button
            id="play-video"
            title="Play Video"
            class="relative focus:outline-none"
            (click)="openVideo()">
            <app-svg-icon
              [icon]="'playIcon'"
              [iconHeight]="100"
              [iconWidth]="100"
              [iconViewBox]="'0 0 109 109'"></app-svg-icon>
            <span class="play-icon-animation"></span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <app-play-area-activities></app-play-area-activities>
</section>

<!-- Hygiene First Section -->
<section class="bg-white font-poppins">
  <div class="m-auto h-full max-w-7xl px-7 py-16 xl:px-0">
    <div class="mb-16 animate-reveal">
      <h1 class="mb-8 text-center text-3xl font-extrabold lg:text-5xl">
        Hygiene First
      </h1>
      <p class="m-auto w-full text-center text-gray-600 md:w-1/2">
        Disinfecting and deep cleaning post each session in order to provide the
        highest standard of health & safety.
      </p>
    </div>
    <div class="grid grid-cols-1 gap-3 font-poppins lg:grid-cols-2 lg:gap-3">
      <div
        class="flex animate-slideAndAppearFromBottom flex-nowrap lg:justify-center">
        <div class="flex content-center justify-center pr-1 pt-2 lg:pr-5">
          <app-svg-icon
            [icon]="'sanitizeHands'"
            [iconHeight]="75"
            [iconWidth]="75"></app-svg-icon>
        </div>
        <div
          class="flex flex-col content-center border-l border-black pl-5 pt-2">
          <p class="mb-4 text-base font-semibold text-color-primary sm:text-lg">
            SANITIZE HANDS
          </p>
          <p class="mb-4 text-sm font-medium text-gray-700 sm:text-base">
            Everyone entering the play area needs to sanitize their hands as we
            believe in health and hygiene.
          </p>
        </div>
      </div>

      <div
        class="flex animate-slideAndAppearFromBottom flex-nowrap lg:justify-center">
        <div class="flex content-center justify-center pr-5 pt-2">
          <app-svg-icon
            [icon]="'shoe'"
            [iconHeight]="60"
            [iconWidth]="60"></app-svg-icon>
        </div>
        <div
          class="flex flex-col content-center border-l border-black pl-5 pt-2">
          <p class="mb-4 text-base font-semibold text-color-primary sm:text-lg">
            REMOVE SHOES
          </p>
          <p class="mb-4 text-sm font-medium text-gray-700 sm:text-base">
            Let all the germs stay out of our premises. Enter without footwears
            and will make sure that you don’t feel itchy feet.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Pricing Section -->
<section class="animate-reveal bg-color-secondary">
  <div class="m-auto h-full max-w-7xl px-7 py-16 xl:px-0">
    <div
      class="grid grid-cols-1 gap-2 font-poppins sm:grid-cols-3 xl:grid-cols-4">
      <div class="mb-3 flex flex-col content-center justify-center sm:mb-0">
        <p class="mb-2 text-base font-semibold text-color-primary sm:mb-4">
          PRICING
        </p>
        <h1 class="mb-2 text-3xl font-bold sm:mb-4 md:text-4xl">Entry Fees</h1>
        <span class="relative bottom-3">
          1 Adult can enter for free with each kid
        </span>
        <p
          class="cursor-pointer text-xl font-semibold text-color-primary transition-all duration-500 ease-in-out hover:text-gray-800">
          *T&C Apply
        </p>
      </div>
      <div
        class="mx-auto flex flex-col content-center justify-center sm:hidden">
        <div class="rounded-2xl border bg-rose-500 p-10 text-center text-white">
          <p class="mb-2 text-base font-medium md:mb-4">Kid Entry</p>
          <h1 class="mb-2 text-xl font-bold md:mb-4 md:text-2xl">
            <span class="mb-1 block">
              <i class="fa-solid fa-indian-rupee-sign mr-1 text-xl"></i>
              499 for 1st Hour
            </span>
            <span class="mb-1 block text-base font-normal">
              (<i
                class="fa-solid fa-indian-rupee-sign mr-0.5 text-sm"></i>
              99 per extra Hour)
            </span>
            <span class="mb-1 block text-base font-normal">
              1 Parent Entry Free
            </span>
          </h1>
        </div>
        <div class="my-3 rounded-2xl border bg-rose-500 p-10 text-center text-white">
          <p
            class="mb-2 block text-base font-extrabold text-yellow-200 md:mb-4">
            INAUGURAL OFFER
          </p>
          <p class="mb-2 block text-base font-medium md:mb-4">Toddler Entry</p>
          <h1 class="mb-5 text-xl font-bold md:text-2xl">
            <span class="mb-1 block">
              <i
                class="fa-solid fa-indian-rupee-sign mr-1 text-lg sm:text-xl"></i>
              399 for 1st Hour
            </span>
            <span class="mb-1 block text-base font-normal">
              (<i
                class="fa-solid fa-indian-rupee-sign mr-0.5 text-sm"></i>
              99 per extra Hour)
            </span>
            <span class="mb-1 block text-base font-normal">
              1 Parent Entry Free
            </span>
          </h1>
        </div>
        <div class="mb-3 rounded-2xl border bg-rose-500 p-10 text-center text-white">
          <p
            class="mb-2 block text-base font-extrabold text-yellow-200 md:mb-4">
            INAUGURAL OFFER
          </p>
          <p class="mb-2 block text-base font-medium md:mb-4">Sibling Entry</p>
          <h1 class="mb-2 text-xl font-bold md:mb-4 md:text-2xl">
            <span class="mb-1 block">
              <i
                class="fa-solid fa-indian-rupee-sign mr-1 text-lg sm:text-xl"></i>
              399 for 1st Hour
            </span>
            <span class="mb-1 block text-base font-normal">
              (<i
                class="fa-solid fa-indian-rupee-sign mr-0.5 text-sm"></i>
              99 per extra Hour)
            </span>
            <span class="mb-1 block text-base font-normal">
              1 Parent Entry Free
            </span>
          </h1>
        </div>
        <div class="mb-3 rounded-2xl border bg-rose-500 p-10 text-center text-white">
          <p class="mb-2 text-base font-semibold text-yellow-200 md:mb-4">
            EXTRA ADDONS
          </p>
          <h1 class="mb-2 text-xl font-bold md:mb-4 md:text-2xl">
            <span class="mb-2 block text-xl md:mb-4">
              ADDITIONAL HOUR:
              <span class="block">
                <i
                  class="fa-solid fa-indian-rupee-sign mr-1 text-lg sm:text-xl"></i>
                99
              </span>
            </span>
            <span>
              EXTRA ADULT:
              <span class="block">
                <i
                  class="fa-solid fa-indian-rupee-sign mr-1 text-lg sm:text-xl"></i>
                149
              </span>
            </span>
          </h1>
        </div>
      </div>
      <div
        class="mx-auto flex-col content-center justify-center rounded-2xl border bg-rose-500 p-10 text-center text-white hidden sm:flex">
        <p class="mb-2 text-lg font-semibold md:mb-4">Kid Entry</p>
        <h1 class="mb-2 text-xl font-bold md:mb-4 md:text-2xl">
          <span class="mb-1 block">
            <i class="fa-solid fa-indian-rupee-sign mr-1 text-xl"></i>
            499 for 1st Hour
          </span>
          <span class="mb-1 block text-base font-normal">
            (<i
              class="fa-solid fa-indian-rupee-sign mr-0.5 text-sm"></i>
            99 per extra Hour)
          </span>
          <span class="mb-1 block text-base font-normal">
            1 Parent Entry Free
          </span>
        </h1>
        <div class="my-3 block xl:hidden">
          <p
            class="mb-2 block text-base font-extrabold text-yellow-200 md:mb-4">
            INAUGURAL OFFERS
          </p>
          <p class="mb-2 block text-lg font-semibold md:mb-4">Toddler Entry</p>
          <h1 class="mb-5 text-xl font-bold md:text-2xl">
            <span class="mb-1 block">
              <i
                class="fa-solid fa-indian-rupee-sign mr-1 text-lg sm:text-xl"></i>
              399 for 1st Hour
            </span>
            <span class="mb-1 block text-base font-normal">
              (<i
                class="fa-solid fa-indian-rupee-sign mr-0.5 text-sm"></i>
              99 per extra Hour)
            </span>
            <span class="mb-1 block text-base font-normal">
              1 Parent Entry Free
            </span>
          </h1>
          <p class="mb-2 mt-2 block text-lg font-semibold md:mb-4">Sibling Entry</p>
          <h1 class="mb-2 text-xl font-bold md:mb-4 md:text-2xl">
            <span class="mb-1 block">
              <i
                class="fa-solid fa-indian-rupee-sign mr-1 text-lg sm:text-xl"></i>
              399 for 1st Hour
            </span>
            <span class="mb-1 block text-base font-normal">
              (<i
                class="fa-solid fa-indian-rupee-sign mr-0.5 text-sm"></i>
              99 per extra Hour)
            </span>
            <span class="mb-1 block text-base font-normal">
              1 Parent Entry Free
            </span>
          </h1>
        </div>
        <div class="mb-3 block sm:hidden">
          <p class="mb-2 text-base font-semibold text-yellow-200 md:mb-4">
            EXTRA ADDONS
          </p>
          <h1 class="mb-2 text-xl font-bold md:mb-4 md:text-2xl">
            <span class="mb-2 block text-xl md:mb-4">
              ADDITIONAL HOUR:
              <span class="block">
                <i
                  class="fa-solid fa-indian-rupee-sign mr-1 text-lg sm:text-xl"></i>
                99
              </span>
            </span>
            <span>
              EXTRA ADULT:
              <span class="block">
                <i
                  class="fa-solid fa-indian-rupee-sign mr-1 text-lg sm:text-xl"></i>
                149
              </span>
            </span>
          </h1>
        </div>
      </div>
      <div
        class="mx-auto hidden border bg-rose-500 p-10 text-center text-white xl:flex xl:flex-col xl:content-center xl:justify-center xl:rounded-2xl">
        <p class="mb-2 text-base font-extrabold text-yellow-200 md:mb-4">
          INAUGURAL OFFERS
        </p>
        <p class="mb-2 text-lg font-semibold md:mb-4">Toddler Entry</p>
        <h1 class="mb-2 text-xl font-bold md:mb-5 md:text-2xl">
          <span class="mb-1 block">
            <i class="fa-solid fa-indian-rupee-sign mr-1 text-xl"></i>
            399 for 1st Hour
          </span>
          <span class="mb-1 block text-base font-normal">
            (<i
              class="fa-solid fa-indian-rupee-sign mr-0.5 text-sm"></i>
            99 per extra Hour)
          </span>
          <span class="mb-1 block text-base font-normal">
            1 Parent Entry Free
          </span>
        </h1>
        <p class="mb-2 text-lg font-semibold md:mb-4">Sibling Entry</p>
        <h1 class="text-xl font-bold md:text-2xl">
          <span class="block">
            <i class="fa-solid fa-indian-rupee-sign mr-1 text-xl"></i>
            399 for 1st Hour
          </span>
        </h1>
        <!-- <span class="mb-1">(upto 2 siblings)</span> -->
        <span class="mb-1 block text-base font-normal">
          (<i
            class="fa-solid fa-indian-rupee-sign mr-0.5 text-sm"></i>
          99 per extra Hour)
        </span>
        <span class="mb-1 block md:mb-2 text-base font-normal">
          1 Parent Entry Free
        </span>
      </div>
      <div
        class="mx-auto hidden flex-col content-center justify-center rounded-2xl border bg-rose-500 p-10 text-center text-white sm:flex sm:flex-col sm:content-center sm:justify-center">
        <p class="mb-2 text-base font-medium text-yellow-200 md:mb-4">
          EXTRA ADDONS
        </p>
        <h1 class="mb-2 text-xl font-bold md:mb-4 md:text-2xl">
          <span class="mb-2 block md:mb-4">
            ADDITIONAL HOUR:
            <span class="block">
              <i class="fa-solid fa-indian-rupee-sign mr-1 text-xl"></i>
              99
            </span>
          </span>
          <span>
            EXTRA ADULT:
            <span class="block">
              <i class="fa-solid fa-indian-rupee-sign mr-1 text-xl"></i>
              149
            </span>
          </span>
        </h1>
      </div>
    </div>
  </div>
</section>

<section class="bg-white">
  <div class="m-auto h-full max-w-7xl px-7 py-16 xl:px-0 xl:py-32">
    <div class="grid grid-cols-1 gap-0 font-poppins md:grid-cols-4">
      <div
        class="relative flex animate-reveal flex-col content-center bg-transparent pb-8 pl-0 pr-8 sm:pt-8">
        <h3 class="mb-4 text-3xl font-extrabold lg:text-4xl">
          Membership Plans
        </h3>
        <p class="mb-4 font-medium leading-6 text-gray-800">
          Play space is shared with other participants who make individual spot
          reservations.
        </p>
        <p
          class="text-xl font-semibold text-color-primary transition-all duration-500 ease-in-out hover:text-gray-800">
          *T&C Apply
        </p>
        <button
          (click)="redirectToUrl('/contact')"
          class="mt-8 w-2/3 transform rounded-full bg-color-primary p-4 font-bold text-white transition-all duration-500 ease-in-out hover:translate-y-[-4px]"
          style="border-top-left-radius: 0%">
          Book Now
        </button>
      </div>

      <div
        class="z-0 mb-6 flex h-fit animate-reveal flex-col content-center rounded-xl bg-color-yellow p-8 md:z-1 md:mb-0">
        <h3 class="mb-4 text-3xl font-extrabold lg:text-4xl">Gold</h3>
        <p class="mb-4">10 Entries</p>
        <div class="mb-4">
          <p class="mb-3 flex justify-between">
            <span>10 Entries</span>
            <span><i class="fa-solid fa-check text-color-green"></i></span>
          </p>
          <p class="mb-3 flex justify-between">
            <span>1 Entry = 1 Hour</span>
            <span><i class="fa-solid fa-check text-color-green"></i></span>
          </p>
          <p class="mb-3 flex justify-between">
            <span>1 Adult Entry Included</span>
            <span><i class="fa-solid fa-check text-color-green"></i></span>
          </p>
          <p class="mb-3 flex justify-between">
            <span>Valid for 365 days</span>
            <span><i class="fa-solid fa-check text-color-green"></i></span>
          </p>
        </div>
        <h3 class="mb-4 font-extrabold">
          <i
            class="fa-solid fa-indian-rupee-sign text-1xl relative bottom-4 mr-1"></i>
          <span class="happy-star-counter text-3xl lg:text-4xl"></span>
        </h3>
      </div>

      <div
        class="mb-6 flex animate-reveal flex-col content-center rounded-xl bg-color-green bg-cover p-8 md:relative md:bottom-10 md:mb-0 md:h-105">
        <h3 class="mb-8 text-3xl font-extrabold text-white lg:text-4xl">
          Diamond
        </h3>
        <p class="mb-6 text-white">30 Entries</p>
        <div class="mb-10 text-white">
          <p class="mb-3 flex justify-between">
            <span>30 Entries</span>
            <span><i class="fa-solid fa-check"></i></span>
          </p>
          <p class="mb-3 flex justify-between">
            <span>1 Entry = 1 Hour</span>
            <span><i class="fa-solid fa-check"></i></span>
          </p>
          <p class="mb-3 flex justify-between">
            <span>1 Adult Entry Included</span>
            <span><i class="fa-solid fa-check"></i></span>
          </p>
          <p class="mb-3 flex justify-between">
            <span>Valid for 365 days</span>
            <span><i class="fa-solid fa-check"></i></span>
          </p>
          <p class="mb-3 flex justify-between">
            <span>Exclusive 15% Birthday Party Discount Voucher</span>
            <span><i class="fa-solid fa-check"></i></span>
          </p>
        </div>
        <h3 class="font-extrabold">
          <i
            class="fa-solid fa-indian-rupee-sign text-1xl relative bottom-4 mr-1 text-white"></i>
          <span
            class="shooting-star-counter text-4xl text-white lg:text-5xl"></span>
        </h3>
      </div>

      <div
        class="flex h-fit animate-reveal flex-col content-center rounded-xl bg-rose-500 p-8">
        <h3 class="mb-4 text-3xl font-extrabold lg:text-4xl">Platinum</h3>
        <p class="mb-4">20 Entries</p>
        <div class="mb-4">
          <p class="mb-3 flex justify-between">
            <span>20 Entries</span>
            <span><i class="fa-solid fa-check text-color-green"></i></span>
          </p>
          <p class="mb-3 flex justify-between">
            <span>1 Entry = 1 Hour</span>
            <span><i class="fa-solid fa-check text-color-green"></i></span>
          </p>
          <p class="mb-3 flex justify-between">
            <span>1 Adult Entry Included</span>
            <span><i class="fa-solid fa-check text-color-green"></i></span>
          </p>
          <p class="mb-3 flex justify-between">
            <span>Valid for 365 days</span>
            <span><i class="fa-solid fa-check text-color-green"></i></span>
          </p>
        </div>
        <h3 class="mb-4 font-extrabold">
          <i
            class="fa-solid fa-indian-rupee-sign text-1xl relative bottom-4 mr-1"></i>
          <span class="bright-star-counter text-3xl lg:text-4xl"></span>
        </h3>
      </div>
    </div>
  </div>
</section>

<!-- Birthday Section -->
<section class="bg-color-green">
  <div class="m-auto h-full px-7 py-16 lg:px-0 lg:py-32">
    <div class="grid grid-cols-1 gap-0 font-poppins lg:grid-cols-2">
      <div class="mb-4 h-full w-full animate-reveal lg:mb-0 lg:p-8">
        <img
          class="rounded-3xl"
          src="../../../assets/images/tkc/birthday.jpeg"
          alt=""
          width="100%"
          height="100%" />
      </div>
      <div class="flex animate-reveal flex-wrap content-center">
        <div class="h-fit w-4/5">
          <p class="mb-6 text-xl font-medium text-color-primary">
            BIRTHDAY PARTIES
          </p>
          <h1 class="mb-6 text-3xl font-bold md:text-5xl">
            Is Your Birthday Coming Up?
          </h1>
          <p class="mb-10 font-normal">
            Plan a private birthday party for your kids. Decorate an area with
            their favorite colors. Pick a menu specially designed and curated
            for your guests.
          </p>
          <div
            class="mb-10 flex flex-wrap content-center justify-between md:flex-nowrap">
            <div class="flex flex-nowrap content-center justify-start">
              <div class="pr-5">
                <app-svg-icon
                  [icon]="'giftBox'"
                  [iconHeight]="75"
                  [iconWidth]="75"></app-svg-icon>
              </div>
              <div
                class="flex flex-col justify-center border-l border-black pl-5">
                <p class="mb-3 font-semibold">Mon - Thu</p>
                <p class="font-normal">1:00pm - 9:00pm</p>
              </div>
            </div>
            <div class="flex flex-nowrap content-center justify-start">
              <div class="pr-5">
                <app-svg-icon
                  [icon]="'balloon'"
                  [iconHeight]="75"
                  [iconWidth]="75"></app-svg-icon>
              </div>
              <div
                class="flex flex-col justify-center border-l border-black pl-5">
                <p class="mb-3 font-semibold">Fri - Sun</p>
                <p class="font-normal">11:00am - 10:00pm</p>
              </div>
            </div>
          </div>
          <div class="w-40">
            <button
              (click)="redirectToUrl('/contact')"
              class="w-full transform rounded-full bg-color-primary p-4 font-bold text-white transition-all duration-500 ease-in-out hover:translate-y-[-4px]"
              style="border-top-left-radius: 0%">
              Book Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- School Trip Section -->
<section class="bg-color-yellow">
  <div class="m-auto h-full px-7 py-16 lg:px-0 lg:py-32">
    <div class="grid grid-cols-1 gap-0 font-poppins lg:grid-cols-2">
      <div class="mb-4 h-full w-full animate-reveal lg:mb-0 lg:p-8">
        <img
          class="rounded-3xl"
          src="../../../assets/images/tkc/school.webp"
          alt=""
          width="100%"
          height="100%" />
      </div>
      <div class="flex animate-reveal flex-wrap content-center">
        <div class="h-fit w-4/5">
          <p class="mb-6 text-xl font-medium text-color-primary">
            SCHOOL PARTIES
          </p>
          <h1 class="mb-6 text-3xl font-bold md:text-5xl">
            Planning for a school trip?
          </h1>
          <p class="mb-10 font-normal">
            Let your students enjoy team-building activities that promote
            collaboration, communication, and problem-solving skills. Students
            can engage in group challenges, trust-building exercises, and
            cooperative games, fostering a sense of camaraderie and teamwork..
          </p>
          <div
            class="mb-10 flex flex-wrap content-center justify-between md:flex-nowrap">
            <div class="flex flex-nowrap content-center justify-start">
              <div class="pr-5">
                <app-svg-icon
                  [icon]="'giftBox'"
                  [iconHeight]="75"
                  [iconWidth]="75"></app-svg-icon>
              </div>
              <div
                class="flex flex-col justify-center border-l border-black pl-5">
                <p class="mb-3 font-semibold text-color-alternate">Mon - Thu</p>
                <p class="font-normal">1:00pm - 9:00pm</p>
              </div>
            </div>
            <div class="flex flex-nowrap content-center justify-start">
              <div class="pr-5">
                <app-svg-icon
                  [icon]="'balloon'"
                  [iconHeight]="75"
                  [iconWidth]="75"></app-svg-icon>
              </div>
              <div
                class="flex flex-col justify-center border-l border-black pl-5">
                <p class="mb-3 font-semibold text-color-alternate">Fri - Sun</p>
                <p class="font-normal">11:00am - 10:00pm</p>
              </div>
            </div>
          </div>
          <div class="w-40">
            <button
              (click)="redirectToUrl('/contact')"
              class="w-full transform rounded-full bg-color-primary p-4 font-bold text-white transition-all duration-500 ease-in-out hover:translate-y-[-4px]"
              style="border-top-left-radius: 0%">
              Book Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
