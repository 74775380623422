<app-header
  [ngClass]="{
    'fixed z-40 w-full animate-reveal transition-all delay-1000': fixed
  }"></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<div class="fixed bottom-4 right-4 z-50">
  <a
    href="https://wa.me/918848039464?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20The%20Kids%20Capitol"
    target="_blank"
    title="Chat with us">
    <img
      class="h-10 w-10 md:h-12 md:w-12"
      src="../../../assets/images/whatsapp.png"
      alt="Whatsapp logo" />
  </a>
</div>
<!-- <app-home></app-home> -->
