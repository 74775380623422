import { Component } from '@angular/core';

@Component({
  selector: 'app-un-authorized-page',
  templateUrl: './un-authorized-page.component.html',
  styleUrls: ['./un-authorized-page.component.css']
})
export class UnAuthorizedPageComponent {

}
