import { Component, Input } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { SharedService } from "src/app/services/shared.service";
import { WebSocketService } from "src/app/services/web-socket.service";

@Component({
  selector: "app-gallery-page",
  templateUrl: "./gallery-page.component.html",
  styleUrls: ["./gallery-page.component.css"],
})
export class GalleryPageComponent {
  @Input() isTestimonialPage: any;
  @Input() galleryTitle: any;

  galleryImages: any = [];

  constructor(
    private webSocketService: WebSocketService,
    private sharedService: SharedService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.getGalleryImageUrls();
    this.titleService.setTitle("The Kids Capitol Gallery");
    this.meta.updateTag({
      name: "description",
      content: `Gallery page of The Kids Capitol. 
        Discover the excitement and adventure at our kids indoor play area through our photo gallery. Explore images of our indoor playground, filled with fun activities for children of all ages.
        Immerse yourself in the world of imagination and play with our children's play zone photo gallery. Explore our indoor playground through captivating images and see why families love spending time with us.
        Step into a world of wonder and joy with our indoor play area photo gallery. From colorful play structures to engaging activities, our gallery showcases the fun-filled experience awaiting kids at The Kids Capitol.`,
    });
    this.meta.updateTag({
      name: "keywords",
      content:
        "Kids indoor play area, Children's play zone, Indoor playground, Kids entertainment center, Family fun center, Play area for kids, Indoor play space, Kids activity center, Children's playroom, Indoor play park",
    });
  }

  getGalleryImageUrls() {
    let url = "../../../assets/json/gallery-images.json";
    this.webSocketService.getObjects(url).subscribe(
      (data) => {
        this.galleryImages = data;
        if (this.isTestimonialPage) {
          this.galleryImages = this.galleryImages.slice(0, 4);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  redirectToTestimonials() {
    this.sharedService.redirectToPage("/testimonials");
  }
}
