import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { SharedService } from "src/app/services/shared.service";

@Component({
  selector: "app-pricing-page",
  templateUrl: "./pricing-page.component.html",
  styleUrls: ["./pricing-page.component.css"],
})
export class PricingPageComponent {
  constructor(private sharedService: SharedService, private meta: Meta, private titleService: Title) {
    this.titleService.setTitle("The Kids Capitol Pricing");
    this.meta.updateTag({
      name: "description",
      content: `Pricing page of The Kids Capitol.
        Explore our pricing page to discover our range of plans and pricing options. Find the perfect solution for your needs with transparent pricing and flexible payment options.
        Discover the cost of our services with our detailed pricing page. Check Entry prices, view our rate chart, and choose the plan that fits your budget.`,
    });
    this.meta.updateTag({
      name: "keywords",
      content:
        "Pricing, Pricing details, Pricing plans, Price list, Cost information, Entry prices, Rate chart, Package costs, Pricing options.",
    });
  }

  redirectToUrl(url: any) {
    this.sharedService.redirectToPage(url);
  }
}
